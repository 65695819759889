import ViewController from "../../CommonComponents/Base/ViewController";
import { DivWithIntersectionObserver } from "../../CommonComponents/UI/DivWithIntersectionObserver/DivWithIntersectionObserver";
import DraggableBottomSheet from "../../CommonComponents/UI/DraggableBottomSheetAIPreference/DraggableBottomSheet";

import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import StoreSectionFeedModel from "./Models/StoreSectionFeedModel";
import StoreSectionFeedViewModel, {
  SectionType,
} from "./StoreSectionFeedViewModel";
import StoreBannerDetailsWidget from "./Widgets/StoreBannerDetails/StoreBannerDetailsWidget";
import { StoreBannerSectionWidget } from "./Widgets/StoreBannerSectionWidget";
import StoreBannersProductListWidget from "./Widgets/StoreBannersProductList/StoreBannersProductListWidget";
import StoreBannerSubSubCateWidget from "./Widgets/StoreBannerSubSubCate/StoreBannerSubSubCateWidget";
import StoreContentFeedSectionWidget from "./Widgets/StoreContentFeedsSectionWidget";
import { StoreGridIconListWidget } from "./Widgets/StoreGridIconListWidget";
import { StoreHeadlineImageSectionWidget } from "./Widgets/StoreHeadlineImageSectionWidget";
import StoreNewArrivalSectionWidget from "./Widgets/StoreNewArrivalSection/StoreNewArrivalSectionWidget";
import StoreProductListSectionFeedSectionWidget from "./Widgets/StoreProductListSectionFeedSectionWidget";
import StoreProductWithVideoThumbSectionFeedWidget from "./Widgets/StoreProductWithVideoThumbSectionFeedWidget";
import { StoreSmallBannerSectionWidget } from "./Widgets/StoreSmallBannerSectionWidget";
import SmallBannerBottomSheet from "./Widgets/StorSmallBannerAIPreference/SmallBannerAIPreferenceWidget";

interface StoreSectionFeedViewControllerProps {
  model: StoreSectionFeedModel;
  id: string;
  badgeUrlsMap?: any;
}

export default class StoreSectionFeedViewController extends ViewController<
  StoreSectionFeedViewControllerProps,
  StoreSectionFeedViewModel
> {
  constructor(props: StoreSectionFeedViewControllerProps) {
    super(
      props,
      new StoreSectionFeedViewModel({
        model: props.model,
        badgeUrlsMap: props.badgeUrlsMap,
      })
    );
  }

  render() {
    const model = this.viewModel.model;
    // model.contents.products = model.contents.products.map(p => p)
    switch (model.type) {
      case "SINGLE_BANNER_PRODUCT_SHUFFLE":
        return (
          <StoreBannerSubSubCateWidget
            isLoading={this.viewModel.isLoadingProducts}
            onRefreshClick={() => {
              // @ts-ignore
              this.viewModel.onSelectCategory(null, SectionType.SUBSUBCAT);
            }}
            screenWidth={this.state.width}
            title={model.contents.sectionTitle}
            subtitle={model.contents.sectionSubtitle}
            products={model.contents.singleBannerProducts.products}
            bannerImageUrl={model.contents.singleBannerProducts.imageUrl}
            caption={model.contents.singleBannerProducts.caption}
            deeplink={model.contents.singleBannerProducts.deeplink}
          />
        );
      case "GRID_ICON_LIST":
        return (
          <StoreGridIconListWidget
            model={model}
            onGridItemClick={this.viewModel.handleGridItemClick}
          />
        );
      case "MINI_BANNER_SURVEY_AI_PREFERENCE":
        return <SmallBannerBottomSheet imageBanner={model.contents.banners} />;
      case "BANNERS":
        return (
          <StoreBannerSectionWidget
            model={model}
            onBannerIndexChanged={(index) =>
              this.viewModel.changeBannerIndex(index)
            }
            screenWidth={this.state.width}
            showingBannerIndex={this.viewModel.bannerIndex}
          />
        );
      case "SMALL_BANNERS":
        return (
          <StoreSmallBannerSectionWidget
            model={model}
            screenWidth={this.state.width}
          />
        );
      case "BANNERS_DETAILS":
        return (
          <StoreBannerDetailsWidget
            screenWidth={this.state.width}
            bannerWidth={this.state.width - 48}
            title={model.contents.sectionTitle}
            banners={model.contents.bannersDetails}
          />
        );
      case "HORIZONTAL_PRODUCT_LIST":
      case "GRID_PRODUCT_LIST":
        if (this.viewModel.timeRemaining === 0) {
          return <></>;
        }

        return (
          <StoreProductListSectionFeedSectionWidget
            isLoadingProducts={this.viewModel.isLoadingProducts}
            model={model}
            onSelectCategory={(id) => this.viewModel.onSelectCategory(id)}
            screenWidth={this.state.width}
            loadProductsError={this.viewModel.loadProductsError}
            selectedCategoryId={this.viewModel.selectedCategoryId}
            timeRemaining={this.viewModel.timeRemaining}
            layout={
              model.type === "HORIZONTAL_PRODUCT_LIST" ? "HORIZONTAL" : "GRID"
            }
          />
        );
      case "VIDEO_THUMBNAIL":
        if (this.viewModel.timeRemaining === 0) {
          return <></>;
        }

        return (
          <DivWithIntersectionObserver
            onVisibilityChanged={(isVisible) =>
              this.viewModel.changeIsVisible(isVisible)
            }>
            <StoreProductWithVideoThumbSectionFeedWidget
              id={this.props.id}
              isLoadingProducts={this.viewModel.isLoadingProducts}
              model={model}
              onSelectCategory={(id) => this.viewModel.onSelectCategory(id)}
              screenWidth={this.state.width}
              loadProductsError={this.viewModel.loadProductsError}
              selectedCategoryId={this.viewModel.selectedCategoryId}
              timeRemaining={this.viewModel.timeRemaining}
              isVisible={this.viewModel.isShowing}
            />
          </DivWithIntersectionObserver>
        );
      case "HEADLINE_IMAGE":
        return (
          <StoreHeadlineImageSectionWidget
            imageUrl={this.props.model.contents.banners[0].imageUrl}
            screenWidth={this.state.width}
          />
        );
      case "HORIZONTAL_PRODUCT_GRID_LIST":
        return (
          <StoreNewArrivalSectionWidget
            topLeadingIconUrl={model.contents.topLeadingIconUrl}
            products={model.contents.products}
            deeplink={model.contents.seeAllDeeplink}
            subtitle={model.contents.sectionSubtitle}
            title={model.contents.sectionTitle}
            screenWidth={this.state.width}
            cta={model.contents.cta}
          />
        );
      case "BANNERS_PRODUCT_LIST":
        return (
          <StoreBannersProductListWidget
            deeplink={model.contents.seeAllDeeplink}
            screenWidth={this.state.width}
            bannerWidth={
              this.state.width >= 600
                ? this.state.width - 230
                : this.state.width - 48
            }
            subtitle={model.contents.sectionSubtitle}
            title={model.contents.sectionTitle}
            topLeadingIconUrl={model.contents.topLeadingIconUrl}
            slideProps={model.contents.bannersProducts.map((x) => ({
              products: x.products,
              bannerImageUrl: x.imageUrl,
              caption: x.caption,
              pairedProducts: x.pairedProducts,
              deeplink: x.deeplink,
              cta: x.cta,
            }))}
          />
        );
      case "CONTENT_FEED":
        return (
          <StoreContentFeedSectionWidget
            model={model}
            screenWidth={this.state.width}
          />
        );

      default:
        return <></>;
    }
  }
}
