import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import ProductLocalItemModel from "../../CommonComponents/Models/ProductLocalItemModel";
import { CuratedProductsResponse } from "../CuratedProducts/Models/CureatedProductsResponse";

export default class TemppageViewModel extends ViewModel {
  isLoading?: boolean;
  products?: ProductLocalItemModel[] = [];
  won: number = 0;
  idr: number = 0;

  changeIdr(value: number) {
    this.emit(() => {
      this.idr = value;
    });
  }

  converWonToIdr(value: number) {
    return value * 12.9 + 250000;
  }

  calculate() {
    if (this.won === 0) return;
    this.emit(() => {
      this.idr = this.converWonToIdr(this.won);
    });
  }

  resetCalculator() {
    this.emit(() => {
      this.won = 0;
      this.idr = 0;
    });
  }

  changeWon(value: number) {
    this.emit(() => {
      this.won = value;
      // this.idr = this.converWonToIdr(value);
    });
  }

  onLandingpageCustomRequestMessage(url: string) {
    return `Hello TheFepi, saya mau nitip beli barang ini ${url}`;
  }

  loadLandingpageProducts() {
    this.emit(() => {
      this.isLoading = true;
    });

    requestAPI(Endpoints.curatedProducts, {
      parameters: {
        limit: 24,
        startIndex: 0,
        selectedGroup: "jastip",
        selectedSortId: null,
      },
      onSuccess: (response: BaseResponse<CuratedProductsResponse>) => {
        this.emit(() => {
          this.isLoading = false;
          this.products = response.data.items;
        });
      },
      onFailure: () => {
        this.emit(() => {
          this.isLoading = false;
        });
      },
    });
  }
}
