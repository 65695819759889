import React from "react";
import NumberFormat from "react-number-format";
import "./LandingpageScreen.css";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ViewController from "../../CommonComponents/Base/ViewController";
import LandingpageViewModel from "./LandingpageViewModel";
import ProductLocalItemCellWidget from "../../CommonComponents/UI/Cells/ProductLocalItemCellWidget";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";
import "bootstrap/dist/css/bootstrap.min.css";
import { VideoPlayer } from "../../CommonComponents/UI/Cells/VideoPlayer";

class LandingpageScreen extends ViewController {
  renderBg1() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "linear-gradient(90deg, #E9CEF1 20.86%, #FED0DA 57.36%, #F7DDEA 92.02%)",
        }}
      />
    );
  }

  renderBg2() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "linear-gradient(89.35deg, #D3BFFC 10.11%, #EDD1F9 52.03%, #D3BFFC 104.29%)",
        }}
      />
    );
  }

  renderBg3() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "linear-gradient(87.49deg, #BEC7FF 9.8%, #CFF8EF 65.39%, #E8E2FC 94.08%)",
        }}
      />
    );
  }

  constructor(props) {
    super(props, new LandingpageViewModel());
    // idk why the fuck when render is called the viewModel property always undefined, even though its initialized in constructor,
    // probably because nested viewcontroller? who knows
    // temporary workaround
    this.__vm = new LandingpageViewModel();
    this.__vm.emit = (x) => {
      x();
      this.setState({});
    };
    this.__vm.loadLandingpageProducts();
    this.state = { won: "" };
    this.state = { idr: "" };
    //     Harga ini sudah termasuk pajak bea cukai dan biaya shipping dari Korea ke Jakarta
    // Untuk produk yang ringan (seperti anting-anting, kalung, scarf, dll) atau produk dari partner brand kami, kami bisa memberikan harga yang lebih murah
    // Harga dapat berubah tergantung pada berat, nilai tukar, dan lainnya.
    // Harga yang tertera di atas hanyalah perkiraan, fixed price dapat diperoleh dari CS kami.
    this.handleChange = this.handleChange.bind(this);
    this.disclaimers = [];
    this.advantages = [];
    this.video = document.getElementById("ShowCaseHomeFeed");
    mixpanelManager.trackPageView("Landingpage");
  }

  handleChange(event) {
    this.setState({ won: event.target.value });
  }

  getCalc = (event) => {
    if (this.state.won === undefined) {
      this.setState({ idr: 0 });
      return;
    }
    var won = this.state.won.replace(",", "");
    var idr = won.replace(",", "") * 13.5 + 250000;
    this.setState({ idr: idr });
    event.preventDefault();
  };

  resetCalc = (event) => {
    this.setState({ won: "" });
    this.setState({ idr: "" });
  };

  render() {
    return (
      <Scaffold>
        <div className="LandingpageIntroductionContainer">
          <div className="row" id="videoOne">
            <video class="VideoPlayer img-fluid video1" autoPlay muted loop id="VideoPlayerNonController_8821100101340049" playsinline="false" src="https://images.thefepi.com/file/shopable/landing/Top.mov"></video>
          </div>

          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Oneforall.jpg"} alt="gambar8"></img>
          </div>

          <div className="row" id="videoTwo">
            <video class="VideoPlayer img-fluid video1" autoPlay muted loop id="VideoPlayerNonController_8821100101340050" playsinline="false" src="https://images.thefepi.com/file/shopable/landing/KineTylasection.mp4"></video>
          </div>

          <div className="row">
            <div className="LandingpageAdvantagesContainer">
              <div className="LandingpageProductsGrid">
                {this.__vm.products.slice(0, 3).map((p) => (
                  <ProductLocalItemCellWidget model={p} cellWidth={this.state.width / 3 - 24} />
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/SandiagaUno.jpg"} alt="gambar8"></img>
          </div>

          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Aroundtheradar.jpg"} alt="gambar8"></img>
          </div>

          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/7.jpg"} alt="gambar8"></img>
          </div>

          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Beyond.jpg"} alt="gambar8"></img>
          </div>

          <div className="row" id="video3">
            <video class="VideoPlayer img-fluid video1" autoPlay muted loop id="VideoPlayerNonController_8821100101340051" playsinline="false" src="https://images.thefepi.com/file/shopable/landing/Basaland2more.mp4"></video>
          </div>

          <div className="row">
            <div className="col text-center bgShopAcc">
              <a href="https://">
                <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Shopaccessorisng.png"} alt="gambar8"></img>
              </a>
            </div>
          </div>

          <div className="row" id="video4">
            <video
              class="VideoPlayer img-fluid video1"
              autoPlay
              muted
              loop
              id="VideoPlayerNonController_8821100101340053"
              playsinline="false"
              src="https://images.thefepi.com/file/shopable/landing/3D Carousel-Landing Page Rona Duma (2).mp4"
            ></video>
          </div>

          <div className="row">
            <div className="col bgDiscover">
              <p className="txtDiscover">Discover Sports Style (For You) :</p>
              <div className="LandingpageProductsGrid">
                {this.__vm.products.slice(0, 3).map((p) => (
                  <ProductLocalItemCellWidget model={p} cellWidth={this.state.width / 3 - 24} />
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/DiscoverLocal.jpg"} alt="gambar8"></img>
          </div>
          <div className="row" id="video4">
            <video class="VideoPlayer img-fluid video1" autoPlay muted loop id="VideoPlayerNonController_8821100101340052" playsinline="false" src="https://images.thefepi.com/file/shopable/landing/Jovem-syma-sevie.mp4"></video>
          </div>
          <div className="row">
            <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Allforone.jpg"} alt="gambar8"></img>
          </div>
          <div className="row paddingBottomBrand">
            <div className="col-4 paddingLeftRight">
              <a href="https://m.thefepi.com/" target="_blank">
                <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 1.jpg"} alt="gambar8"></img>
              </a>
            </div>
            <div className="col-4 paddingLeftRightCenter">
              <a href="https://m.thefepi.com/" target="_blank">
                <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 2.jpg"} alt="gambar8"></img>
              </a>
            </div>
            <div className="col-4 paddingLeftRightCenterRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 3.jpg"} alt="gambar8"></img>
            </div>

            <div className="col-4  paddingLeftRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 4.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenter">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 5.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenterRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 6.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4  paddingLeftRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 7.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenter">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 8.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenterRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 9.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4  paddingLeftRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 10.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenter">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 11.jpg"} alt="gambar8"></img>
            </div>
            <div className="col-4 paddingLeftRightCenterRight">
              <img className="img-fluid" src={global.ImgUrl + "shopable/landing/Artboard 12.jpg"} alt="gambar8"></img>
            </div>
          </div>
        </div>
      </Scaffold>
    );
  }
}

export default LandingpageScreen;
