import { CacheManager } from "../CacheManager";

const cm = new CacheManager();

export default abstract class ViewModel {
  states: this[] = [];

  key?: string;
  fresh?: Boolean = true;
  isResellerDomain = false;

  constructor(args?: { key?: string }) {
    this.isResellerDomain = !Boolean(window.location.hostname.includes("thefepi.com"));
    if (args?.key) {
      this.key = args?.key;
    }
  }

  refresh() {
    if (this.key) {
      const persisted = cm.get(this.key);
      if (persisted) {
        Object.assign(this, persisted);
        Object.assign(this, { fresh: false, isLoading: false, loadingProducts: false });
      }
    }
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  persist() {
    cm.set(this.key, this.clone());
  }

  emit: (action: () => void) => void = (_) => {
    this.states.push({ ...this });
    this.stabPostUpdateState();
  };

  stabPostUpdateState() {} // For Testing Only
}
