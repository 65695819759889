import React from 'react';
import { mixpanelManager } from '../../Analytics/Mixpanel/Common';

const url = window.location.href;
const lang = url.substring(url.lastIndexOf('/') + 1);

class TermServiceScreen extends React.Component {
    //constructor() {
    //    mixpanelManager.trackPageView("TermService")
    //}

    render() {
        return (
            <div className="container" id="TermService">
                <ul className='list-inline' style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <li className='list-inline-item'>
                        {
                            lang === 'en' || lang === 'TermService' ?
                                <a href="/TermService/en" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px' }}><b>en</b></p></a>
                                :
                                <a href="/TermService/en" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px', color: '#cecece' }}>en</p></a>
                        }

                    </li>
                    <li className='list-inline-item'>
                        <p>|</p>
                    </li>
                    <li className='list-inline-item'>
                        {
                            lang === 'id' ?
                                <a href="/TermService/id" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px' }}><b>id</b></p></a>
                                :
                                <a href="/TermService/id" style={{ color: 'black', textDecoration: 'none' }}><p style={{ fontSize: '15px', color: '#cecece' }}>id</p></a>
                        }
                    </li>
                </ul>

                {
                    lang === 'id' ?
                        <>
                            <div className="row">
                                <div className="col text-left">
                                    <br></br>
                                    <h4 style={{ borderBottom: 'solid black 3px', fontSize: '20px' }}>Syarat dan Ketentuan<br></br><br></br></h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    <ol className="list-inline" style={{ fontWeight: 'bold' }}>
                                        <li>PENDAHULUAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>1.1 &nbsp; &nbsp; Selamat datang di platform PT. Ide Picup Group (&quot;Aplikasi&quot;). Silakan baca Syarat Layanan berikut dengan cermat sebelum menggunakan Aplikasi ini atau membuka akun PT. Ide Picup Group (&quot;Akun&quot;) agar anda mengetahui tentang hak dan kewajiban hukum Anda sehubungan dengan PT. Ide Picup Group International Indonesia dan afiliasinya dan anak perusahaannya (masing-masing dan bersama-sama, &quot;PT. Ide Picup Group&quot; atau &quot;kami&quot;). &quot;Layanan&quot; yang kami berikan atau sediakan termasuk (a) Aplikasi, (b) layanan yang disediakan oleh Aplikasi dan oleh perangkat lunak klien PT. Ide Picup Group yang tersedia melalui Aplikasi, dan (c) semua informasi, halaman tertaut, fitur, data, teks, gambar, foto, grafik, musik, suara, video (termasuk siaran langsung (live stream)), pesan, tag, konten, pemrograman, perangkat lunak, layanan aplikasi (termasuk, dengan tidak terbatas pada, setiap layanan aplikasi mobile) atau materi lainnya yang tersedia melalui Aplikasi atau layanan terkait (&quot;Konten&quot;). Setiap fitur baru yang ditambahkan atau memperbesar Layanan juga tunduk pada Syarat Layanan ini. Syarat Layanan ini mengatur penggunaan Layanan yang disediakan oleh PT. Ide Picup Group.</p>
                                                <p>1.2 &nbsp; &nbsp; Sebelum menjadi Pengguna Aplikasi, Anda harus membaca dan menerima semua syarat dan ketentuan yang berkaitan dengan, Syarat Layanan ini dan Anda harus memberikan persetujuan atas pemrosesan data pribadi Anda sebagaimana dijelaskan dalam Kebijakan Privasi yang terkait dengan Syarat Layanan ini. &nbsp; &nbsp; &nbsp; &nbsp;</p>
                                                <p>1.3 &nbsp; &nbsp; PT. Ide Picup Group berhak untuk mengganti, mengubah, menangguhkan atau menghentikan semua atau bagian apapun dari Aplikasi ini atau Layanan setiap saat atau setelah memberikan pemberitahuan sebagaimana dipersyaratkan oleh undang-undang dan peraturan setempat. PT. Ide Picup Group dapat meluncurkan Layanan tertentu atau fitur tertentu dalam versi beta, yang mungkin tidak berfungsi dengan baik atau sama seperti versi akhir, dan kami tidak bertanggung jawab dalam hal demikian. PT. Ide Picup Group juga dapat membatasi fitur tertentu atau membatasi akses Anda ke bagian atau seluruh Aplikasi atau Layanan atas kebijakannya sendiri dan tanpa pemberitahuan atau kewajiban.</p>
                                                <p>1.4 &nbsp; &nbsp; PT. Ide Picup Group berhak untuk menolak memberikan akses ke Aplikasi atau Layanan kepada Anda atau mengizinkan Anda untuk membuka Akun dengan alasan apapun.</p>
                                                <p>Dengan membuat Akun (sebagaimana didefinisikan di bawah ini) di PT. Ide Picup Group atau menggunakan Layanan PT. Ide Picup Group (sebagaimana didefinisikan di bawah ini), maka Anda sebagai Pemilik Akun (sebagaimana didefinisikan di bawah ini) menyatakan bahwa Anda setuju untuk terikat dengan perjanjian Ketentuan Layanan PT. Ide Picup Group ini serta kebijakan mengenai perubahan atau perbaikan Ketentuan Layanan di kemudian hari (&quot;Ketentuan Layanan PT. Ide Picup Group&quot;).</p>
                                                <p>Bacalah Ketentuan Layanan PT. Ide Picup Group berikut secara seksama sebelum Anda menggunakan Aplikasi dan Layanan PT. Ide Picup Group. Anda dipersilahkan untuk tidak menggunakan Aplikasi dan Layanan PT. Ide Picup Group jika tidak setuju untuk terikat dengan Ketentuan Layanan PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>PRIVASI
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>2.1&nbsp;&nbsp; &nbsp;&nbsp;Privasi anda sangatlah penting bagi kami di PT. Ide Picup Group. Untuk melindungi hak-hak Anda dengan lebih baik kami telah menyediakan PT. Ide Picup Group untuk menjelaskan praktik privasi kami secara rinci. Dengan menggunakan Layanan atau memberikan informasi di Aplikasi, Anda:</p>

                                                <p>(a) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Menyetujui tindakan PT. Ide Picup Group untuk mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah Konten, data pribadi dan Informasi Pengguna Anda sebagaimana dijelaskan dalam Kebijakan Privasi;</p>

                                                <p>(b) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Menyetujui dan mengakui bahwa hak kepemilikan atas Informasi Pengguna dimiliki secara bersama oleh Anda dan PT. Ide Picup Group; dan</p>

                                                <p>(c) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Tidak akan, baik secara langsung maupun tidak langsung, mengungkapkan Informasi Pengguna Anda kepada setiap pihak ketiga, atau sebaliknya memperbolehkan setiap pihak ketiga untuk mengakses atau menggunakan Informasi Pengguna Anda, tanpa persetujuan tertulis sebelumnya dari PT. Ide Picup Group.</p>

                                                <p>2.2&nbsp;&nbsp; &nbsp;&nbsp;Pengguna yang memiliki data pribadi Pengguna lainnya melalui penggunaan Layanan (&quot;Pihak Penerima&quot;) dengan ini setuju bahwa, mereka akan (i) mematuhi semua undang-undang perlindungan data pribadi yang berlaku sehubungan dengan data tersebut; (ii) mengizinkan Pengguna yang data pribadinya telah dikumpulkan oleh Pihak Penerima (&quot;Pihak yang Mengungkapkan&quot;) untuk menghapus datanya yang telah dikumpulkan dari database Pihak Penerima; dan (iii) mengizinkan Pihak yang Mengungkapkan untuk meninjau informasi apa saja yang telah dikumpulkan tentang mereka oleh Pihak Penerima, dalam setiap kasus (ii) dan (iii) di atas, dilakukan sesuai dengan dan bilamana diwajibkan oleh hukum yang berlaku.</p>

                                            </p>
                                        </li>
                                        <li>IZIN TERBATAS
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>3.1&nbsp;&nbsp; &nbsp;&nbsp;PT. Ide Picup Group memberikan izin terbatas yang dapat dicabut kembali kepada Anda untuk mengakses Layanan dengan tunduk pada syarat dan ketentuan Syarat Layanan ini. Semua Konten eksklusif, merek dagang, merek layanan, nama merek, logo dan kekayaan intelektual lainnya (&quot;Kekayaan Intelektual&quot;) yang ditampilkan di Aplikasi merupakan milik PT. Ide Picup Group dan bila berlaku, pemilik pihak ketiga yang diidentifikasi dalam Aplikasi. Tidak ada hak atau izin yang diberikan secara langsung atau tidak langsung kepada pihak mana pun yang mengakses Aplikasi untuk menggunakan atau memperbanyak Kekayaan Intelektual, dan tidak ada pihak yang mengakses Aplikasi yang dapat mengklaim hak, kepemilikan atau kepentingan apapun di dalamnya. Dengan menggunakan atau mengakses Layanan Anda setuju untuk mematuhi hak cipta, merek dagang, merek layanan, dan semua undang-undang yang berlaku lainnya yang melindungi Layanan, Aplikasi dan Kontennya. Anda setuju untuk tidak menyalin, mendistribusikan, mempublikasikan ulang, mengirimkan, menampilkan secara terbuka, melakukan secara terbuka, mengubah, menyesuaikan, menyewa, menjual, atau membuat karya turunan dari bagian apapun dari Layanan, Aplikasi atau Kontennya. Anda juga tidak boleh, tanpa persetujuan tertulis sebelumnya dari kami, me-mirror&nbsp;atau membingkai bagian apapun atau seluruh konten Aplikasi ini di server lain mana pun atau sebagai bagian dari Aplikasi web lain apapun. Selain itu, anda setuju bahwa Anda tidak akan menggunakan robot,&nbsp;spider&nbsp;atau perangkat otomatis maupun proses manual lain apapun untuk memantau atau menyalin Konten kami, tanpa persetujuan tertulis sebelumnya dari kami (persetujuan tersebut dianggap diberikan untuk teknologi mesin pencari standar yang digunakan oleh Aplikasi web pencari Internet guna mengarahkan pengguna Internet ke Aplikasi web ini).</p>

                                                <p>3.2&nbsp;&nbsp; &nbsp;&nbsp;Anda setuju bahwa PT. Ide Picup Group dapat untuk alasan apapun atas kebijakannya sendiri dan dengan atau tanpa pemberitahuan atau kewajiban kepada anda atau pihak ketiga mana pun, menambah, mengganti, dan/atau memodifikasi, model metode, algoritma atau proses otomatis lainnya yang tersedia saat menyediakan Layanan, termasuk namun tidak terbatas pada layanan yang terkait dengan Iklan PT. Ide Picup Group atau layanan lain yang disediakan oleh PT. Ide Picup Group.</p>

                                                <p>3.3&nbsp;&nbsp; &nbsp;&nbsp;Anda boleh membuat tautan ke Aplikasi dari Aplikasi web Anda, asalkan Aplikasi web Anda tidak menyiratkan adanya dukungan oleh atau asosiasi dengan PT. Ide Picup Group. Anda mengakui bahwa atas kebijakannya sendiri dan setiap saat PT. Ide Picup Group dapat berhenti menyediakan Layanan, baik sebagian atau secara keseluruhan, tanpa pemberitahuan.</p>

                                            </p>
                                        </li>
                                        <li>PERANGKAT LUNAK
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Setiap perangkat lunak yang disediakan oleh kami kepada Anda sebagai bagian dari Layanan tunduk pada ketentuan Syarat Layanan ini. PT. Ide Picup Group mencadangkan semua hak atas perangkat lunak yang tidak secara tegas diberikan oleh PT. Ide Picup Group dalam Syarat Layanan ini. Setiap skrip atau kode pihak ketiga yang tertaut atau dirujuk dari Layanan dilisensikan kepada anda oleh pihak ketiga yang memiliki skrip atau kode tersebut, bukan oleh PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>AKUN DAN KEAMANAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>5.1 Anda menyatakan dan menjamin bahwa Anda adalah orang yang cakap menurut hukum dan memiliki kewenangan untuk mengikatkan diri dalam perjanjian dan melaksanakan hak dan kewajibannya berdasarkan perjanjian.</p>
                                                <p>Untuk dapat mengakses dan menggunakan Layanan PT. Ide Picup Group, Anda harus mendaftar di PT. Ide Picup Group dengan membuat Akun.</p>
                                                <p>5.2 Pembuatan Akun meliputi pemberian nama lengkap Anda, tanggal lahir, alamat domisili, nomor telepon, alamat email yang valid. PT. Ide Picup Group dapat menolak aplikasi Anda untuk pembuatan Akun, atau membatalkan Akun yang ada, karena alasan apapun, atas kebijakan PT. Ide Picup Group sendiri.</p>
                                                <p>5.3 Anda bertanggung jawab untuk menjaga keamanan kata sandi Akun Anda. PT. Ide Picup Group tidak dan tidak akan bertanggung jawab atas kehilangan atau kerusakan apa pun dari kegagalan Anda menjaga keamanan Akun dan kata sandi Anda.</p>
                                                <p>5.4 PT. Ide Picup Group tidak akan meminta kata sandi maupun kode OTP terkait Akun Anda untuk alasan apapun, oleh karena itu PT. Ide Picup Group menghimbau untuk tidak memberikan kata sandi atau kode OTP Akun Anda kepada pihak manapun, baik kepada pihak ketiga maupun kepada pihak yang mengatasnamakan PT. Ide Picup Group.</p>
                                                <p>5.5 Anda menyatakan bahwa Anda bertanggung jawab atas Konten terkait Akun Anda dan semua aktivitas yang Anda lakukan sehubungan dengan penggunaan Layanan dan Konten.</p>
                                                <p>5.6 Anda setuju bahwa PT. Ide Picup Group akan menggunakan alamat email dan nomor telepon yang Anda berikan sebagai metode utama untuk komunikasi.</p>
                                                <p>5.7 Jika PT. Ide Picup Group mencurigai adanya aktivitas yang melanggar Ketentuan Layanan PT. Ide Picup Group, maka PT. Ide Picup Group berhak untuk memberi label &quot;Akun Mencurigakan&quot; pada Akun tersebut.</p>
                                                <p>5.8 Dalam hal terdapat pelanggaran apapun terhadap Ketentuan Layanan PT. Ide Picup Group, sebagaimana ditentukan berdasarkan kebijaksanaan PT. Ide Picup Group, PT. Ide Picup Group berhak dengan segera menghentikan Layanan untuk Anda.</p>
                                            </p>
                                        </li>
                                        <li>Ketentuan Seller Shop
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>6.1. Tipe konten seller</p>
                                                <p>a) style: Foto styling seller diposting pada bagian feed (seller&rsquo;s creation)</p>
                                                <p>b) Produk: Setiap produk yang terdaftar/ ditandai oleh seller (sama dengan produk di toko kami)</p>

                                                <p>6.2. Ketentuan/Pembatasan Konten:</p>
                                                <p>a) Setiap foto harus, paling tidak men-tag 1 produk (Foto yang diposting tanpa tag produk, tidak diperkenankan)</p>
                                                <p>b) Produk yang TIDAK dibeli oleh seller atau TIDAK di tag pada foto styling juga BISA dicantumkan di seller shop.</p>
                                                <p>c) Jika jumlah produk yang dibeli &amp; terdaftar: 10, Seller dapat mencantumkan produk yang TIDAK DIBELI hingga 10</p>
                                                <p>d)Jika produk tidak dibeli dari kami, tetapi dijual di TOKO kami, Seller dapat men-tag produk tersebut.</p>
                                                <p>e) Jika produk yang seller ingin tag/jual belum masuk di TOKO kami, maka TIDAK BISA untuk di tag. (Namun jika seller ingin menjual, bisa request seperti Jastip)</p>

                                                <p>6.3. Minimum Posting untuk membuka seller shop;</p>
                                                <p>a)Style: Min. 4 posting atau lebih (tidak ada batas maksimal)</p>
                                                <p>b)Produk: Min. 4 produk (min. 1 produk per style)</p>
                                                <p>c)Maksimal tag 5 produk dalam 1 foto style</p>

                                                <p>6.4. Untuk produk, Total batas jumlah Tagging;</p>
                                                <p>a)Pembukaan awal: Sebanyak 3 seller dapat men-tag / menjual produk (First come, First Serve)</p>
                                                <p>b)Tetapi jika seller telah membeli produk itu, penjual dapat memberi tag/menjual tanpa batas</p>

                                                <p>6.5. Kriteria upload untuk Style by seller</p>
                                                <p>a)Berdasarkan foto dimana item dalam pakaian dapat dilihat JELAS (Penuh atau Sebagian)</p>
                                                <p>b)Resolusi yang rendah, komposisi yang buruk, atau outfit yang tidak terlihat jelas dapat ditolak</p>
                                                <p>c)Foto yang tidak ada hubungannya dengan foto styling, konten yang tidak patut dan foto yang memakai toko/merek/produk Anda sendiri akan ditolak</p>

                                                <p>6.6. Proses penyaringan untuk foto</p>
                                                <p>a)Pembukaan awal: Akan diposting setelah konsultasi sebelumnya dengan seller terpilih</p>
                                                <p>b)Pada dasarnya, proses &apos;pra-penyaringan&apos; untuk konten yang diunggah oleh penjual DIHAPUS</p>
                                                <p>c)Penyaringan dan panduan dilakukan secara acak oleh staff secara acak</p>
                                                <p>d)Fungsi report akan diperkenalkan, sehingga pengguna bisa mereport gambar berbahaya secara langsung, dan staff akan meninjau terlebih dahulu gambar tersebut.</p>
                                                <p>e)Tindakan koreksi/penghapusan gambar (pertama) &gt; bila diulang (lebih dari 3 kali), akan dilakukan tindakan peringatan/penangguhan akun</p>

                                                <p>6.7. Seller Reward&nbsp;</p>
                                                <p>a) Saat customer membeli produk melalui posting/tlink/pengiriman penjual, maka reward mulai dihasilkan!</p>
                                                <p>b)Pendapatan reward: 5% dari harga produk (ditetapkan jumlahnya di pembukaan awal)</p>

                                                <p>6.8. Persyaratan Monetisasi Reward Seller;</p>
                                                <p>a)Ketika customer membeli melalui seller shop, 5% dari harga jual diakui sebagai &lsquo;Unconfirmed balance(revenue)&rsquo;</p>
                                                <p>b)Setelah &lsquo;purchase confirmation&rsquo; (&lsquo;Order complete&rsquo;) diubah menjadi &lsquo;Withdrawable Balance(revenue)&rsquo; maka sudah dapat diuangkan</p>
                                                <p>c)&lsquo;Withdrawable balance&rsquo; dapat ditarik minimal di atas Rp. 50,000 (IDR)</p>

                                                <p>6.9. Pilihan Monetisasi Reward;</p>
                                                <p>a) Pilihan monetisasi yang tersedia: i. Pembayaran tunai ii. SHOP Point iii. Donation</p>
                                                <p>b)&apos;Cash-out&apos; dilakukan melalui Transfer Bank - Mewajibkan informasi seller (nama, KTP, dll) - Seller memasukkan informasi Bank/Nomor Rekening /Nama pemegang rekening - Penarikan tunai setelah PPN atau pemotongan pajak 10% - Dikenakan biaya transaksi saat transfer ke bank selain bank terdaftar perusahaan kami - proses membutuhkan 3-5 hari setelah pengajuan</p>
                                                <p>c)Jika telah dikonversi menjadi Shop point, maka tidak bisa dikembalikan menjadi uang atau membatalkan konversi</p>

                                                <p>6.10. Skema Donasi Seller;</p>
                                                <p>a)Seller dapat menetapkan jumlah donasinya dalam hitungan % sebelum memulai penjualan (jika ditetapkan 0.5%, maka keuntungan penjual dari 5% menjadi 4,5%)</p>
                                                <p>b)Detail pengaturan donasi Seller juga diberitahukan kepada customer terlebih dahulu di Seller Shop (misal, Rp.xxx akan didonasikan ke xxx)</p>
                                                <p>c)Donasi bisa dipilih bahkan setelah proses monetisasi setelah penjualan</p>
                                                <p>6.11. Bagaimana Donasi Seller bekerja;</p>
                                                <p>a)Kemitraan dengan Komunitas/Kelompok Sosial terkait (misal. Perlindungan Lingkungan, Kesejahteraan Anak, Perawatan Hewan, dll)</p>
                                                <p>b)Perusahaan mempromosikan/mengekspos progres kegiatan donasi setiap bulan secara berkala</p>
                                            </p>
                                        </li>
                                        <li>PERSYARATAN PENGGUNAAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>7. &nbsp; &nbsp; &nbsp;PERSYARATAN PENGGUNAAN</p>
                                                <p>7.1 &nbsp; &nbsp; Izin untuk menggunakan Aplikasi dan Layanan ini berlaku sampai diakhiri. Izin ini akan berakhir sebagaimana diatur dalam Syarat Layanan ini atau jika Anda gagal mematuhi persyaratan atau ketentuan apapun dari Syarat Layanan ini. Dalam hal demikian, PT. Ide Picup Group dapat melakukan pengakhiran tersebut dengan atau tanpa memberikan pemberitahuan kepada Anda.</p>
                                                <p>7.2 &nbsp; &nbsp; Anda setuju untuk tidak:</p>
                                                <p>(a) &nbsp; &nbsp; &nbsp; &nbsp;mengunggah, memasang (posting), mengirimkan atau menyediakan Konten yang melanggar hukum, berbahaya, mengancam, kasar, melecehkan, mengkhawatirkan, meresahkan, berliku-liku, memfitnah, vulgar, cabul, mencemarkan, invasif terhadap privasi pihak lain, penuh kebencian, atau mengandung unsur SARA atau lainnya;</p>
                                                <p>(b) &nbsp; &nbsp; &nbsp; &nbsp;mengunggah, memasang (posting), mengirimkan atau sebaliknya menyediakan Konten &nbsp;yang menampilkan anak di bawah umur tanpa pengawasan atau menggunakan Layanan untuk merugikan anak di bawah umur dengan cara apapun;</p>
                                                <p>(c) &nbsp; &nbsp; &nbsp; &nbsp;menggunakan Layanan atau mengunggah Konten untuk menyamar sebagai orang atau entitas lain, atau kalau tidak memalsukan afiliasi anda dengan seseorang atau suatu entitas;</p>
                                                <p>(d) &nbsp; &nbsp; &nbsp; &nbsp;memalsukan kop atau kalau tidak memanipulasi pengidentifikasi guna menyamarkan asal Konten apapun yang dikirimkan melalui Layanan;</p>
                                                <p>(e) &nbsp; &nbsp; &nbsp; &nbsp; menghapus pemberitahuan mengenai hak kepemilikan dari Aplikasi;</p>
                                                <p>(f) &nbsp; &nbsp; &nbsp; &nbsp;menyebabkan, mengizinkan atau memberikan wewenang untuk modifikasi, pembuatan karya turunan, atau penerjemahan Layanan tanpa izin yang jelas dari PT. Ide Picup Group;</p>
                                                <p>(g) &nbsp; &nbsp; &nbsp; &nbsp;menggunakan Layanan untuk manfaat pihak ketiga atau dengan cara yang tidak diizinkan oleh izin yang diberikan di sini;</p>
                                                <p>(h) &nbsp; &nbsp; &nbsp; &nbsp; menggunakan Layanan atau mengunggah Konten dengan cara yang menipu, membohongi, memalsukan, menyesatkan, atau memperdayakan ;</p>
                                                <p>(i) &nbsp; &nbsp; &nbsp; &nbsp; membuka dan/atau mengoperasikan beberapa akun pengguna sehubungan dengan tindakan yang secara jelas melanggar Syarat Layanan ini atau maksud dari Syarat Layanan ini;</p>
                                                <p>(j) &nbsp; &nbsp; mengakses Aplikasi, membuka akun pengguna, atau sebaliknya mengakses akun pengguna dengan menggunakan segala perangkat keras dan perangkat lunak tidak resmi dari PT. Ide Picup Group, termasuk namun tidak terbatas pada, emulator, simulator, bot, atau perangkat keras maupun perangkat lunak sejenisnya;</p>
                                                <p>(k) &nbsp; &nbsp; &nbsp; &nbsp; memanipulasi harga barang apapun atau mengganggu daftar Pengguna lain;</p>
                                                <p>(l) &nbsp; &nbsp; &nbsp;melakukan tindakan yang dapat merusak sistem umpan balik atau peringkat;</p>
                                                <p>(m) &nbsp; &nbsp; &nbsp; &nbsp;mencoba mendekompilasi, merekayasa balik, membongkar atau meretas Layanan (atau bagian apapun darinya), atau mengalahkan atau mengatasi teknologi enkripsi atau langkah-langkah keamanan yang diimplementasikan oleh PT. Ide Picup Group sehubungan dengan Layanan dan/atau data yang dikirim, diolah atau disimpan oleh PT. Ide Picup Group;</p>
                                                <p>(n) &nbsp; &nbsp; &nbsp; &nbsp;memungut atau mengumpulkan informasi apapun tentang atau mengenai pemegang Akun lain, termasuk, dengan tidak terbatas pada, data atau informasi pribadi apapun;</p>
                                                <p>(o) &nbsp; &nbsp; &nbsp; &nbsp;mengunggah, mengirim email, memposting, mengirimkan atau menyediakan Konten apapun yang tidak berhak untuk anda sediakan berdasarkan hukum atau ikatan kontrak atau fidusia (seperti informasi orang dalam, informasi eksklusif dan rahasia yang dipelajari atau diungkapkan sebagai bagian dari hubungan kerja atau di bawah perjanjian non pengungkapan);</p>
                                                <p>(p) &nbsp; &nbsp; &nbsp; &nbsp;mengunggah, mengirim email, memposting, mengirimkan atau menyediakan Konten apapun yang melanggar hak paten, merek dagang, rahasia dagang, hak cipta atau hak kepemilikan lainnya dari pihak mana pun;</p>
                                                <p>(q) &nbsp; &nbsp; &nbsp; &nbsp; mengunggah, mengirim email, memposting, mengirimkan atau menyediakan iklan, materi promosi yang tidak diinginkan atau tidak sah, &quot;surat sampah&quot;, &quot;spam&quot;, &quot;surat berantai&quot;, &quot;skema piramida&quot;, atau bentuk ajakan lainnya yang tidak sah;</p>
                                                <p>(r) &nbsp; &nbsp; &nbsp; &nbsp;mengunggah, mengirim email, memposting, mengirimkan atau menyediakan materi yang berisikan virus, worm, Trojan-horse perangkat lunak atau kode, rutin, file maupun program komputer lainnya yang dirancang untuk secara langsung atau tidak langsung memengaruhi, memanipulasi, mengganggu, menghancurkan atau membatasi fungsionalitas atau integritas perangkat lunak atau perangkat keras komputer atau data atau perlengkapan telekomunikasi apapun;</p>
                                                <p>(s) &nbsp; &nbsp; &nbsp; &nbsp; mengganggu aliran normal dialog, menyebabkan layar &quot;bergulir&quot; lebih cepat daripada kecepatan mengetik Pengguna Layanan lainnya, atau melakukan tindakan yang secara negatif memengaruhi kemampuan Pengguna lain untuk berkomunikasi dalam waktu nyata;</p>
                                                <p>(t) &nbsp; &nbsp; &nbsp; &nbsp;memengaruhi, memanipulasi atau mengganggu Layanan atau server atau jaringan yang terhubung ke Layanan atau penggunaan dan kenyamanan menikmati Layanan oleh Pengguna lainnya, atau tidak menaati persyaratan, prosedur, kebijakan atau peraturan jaringan yang terhubung ke Aplikasi;</p>
                                                <p>(u) &nbsp; &nbsp; &nbsp; melakukan tindakan atau terlibat dalam perilaku yang secara langsung atau tidak langsung dapat merusak, melumpuhkan, membebani secara berlebihan, atau mengganggu Layanan atau server atau jaringan yang terhubung ke Layanan;</p>
                                                <p>(v) &nbsp; &nbsp; &nbsp; menggunakan Layanan untuk sengaja atau tidak sengaja melanggar hukum, aturan, kode, arahan, pedoman, kebijakan atau peraturan setempat, negara bagian, nasional atau internasional yang berlaku termasuk, dengan tidak terbatas pada, undang-undang dan persyaratan (baik yang berkekuatan hukum maupun tidak) yang berkaitan dengan anti pencucian uang atau anti terorisme.</p>
                                                <p>(w) &nbsp; &nbsp; &nbsp; &nbsp;menggunakan Layanan untuk melanggar privasi pihak lainnya atau untuk &quot;menguntit&quot; atau mengganggu pihak lain;</p>
                                                <p>(x) &nbsp; &nbsp; &nbsp; &nbsp;melanggar hak PT. Ide Picup Group, termasuk setiap hak kekayaan intelektual dan setiap pemboncengan reputasi (passing off) atas hak kekayaan intelektual tersebut;</p>
                                                <p>(y) &nbsp; &nbsp; menggunakan Layanan untuk mengumpulkan atau menyimpan data pribadi tentang Pengguna lainnya sehubungan dengan perilaku dan kegiatan terlarang yang ditetapkan di atas; dan/atau</p>
                                                <p>(z) &nbsp; &nbsp; mendaftarkan barang yang melanggar hak cipta, merek dagang atau hak kekayaan intelektual pihak ketiga lainnya atau menggunakan layanan dengan cara yang akan melanggar hak kekayaan intelektual pihak lain; dan/atau</p>
                                                <p>(aa) &nbsp; &nbsp; &nbsp;meminta, berkorespondensi, dan/atau terlibat dengan, atau menyediakan informasi terkait aktivitas pengguna saat menggunakan Layanan kepada media dan/atau perusahaan riset, untuk tujuan apapun termasuk namun tidak terbatas kepada riset pasar, survei kepuasan, atau bentuk pemasaran lainnya tanpa persetujuan tertulis dari PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>PELANGGARAN TERHADAP SYARAT LAYANAN KAMI
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Kami berhak untuk mengubah atau menghentikan Layanan karena alasan apapun, dengan memberikan pemberitahuan kepada Pemilik Akun sebelum penghentian atau perubahan.</p>
                                                <p>Kami berhak menolak memberikan Layanan kepada siapa pun dengan alasan apa pun kapan saja.</p>
                                                <p>Kami mungkin, tetapi tidak memiliki kewajiban untuk, menghapus Konten dan menangguhkan atau menghentikan Akun jika kami memutuskan dengan kebijakan kami sendiri bahwa barang atau jasa yang ditawarkan, atau Konten yang diunggah atau dipostingkan ke Toko Online, melanggar Ketentuan Layanan PT. Ide Picup Group maupun ketentuan hukum dan regulasi yang berlaku.</p>
                                                <p>Penyalahgunaan Akun dalam bentuk apapun secara verbal atau tertulis (termasuk ancaman penyalahgunaan atau ancaman permintaan ganti rugi) atas setiap pelanggan PT. Ide Picup Group, karyawan, anggota, atau petugas PT. Ide Picup Group akan mengakibatkan penghentian Akun segera.</p>
                                                <p>PT. Ide Picup Group tidak melakukan pra-penyaringan Konten dan dalam hal ini, PT. Ide Picup Group memiliki kebijakan mutlak untuk menolak atau menghapus Konten apapun dari Layanan, termasuk Toko Online.</p>
                                                <p>Kami berhak untuk memberikan Layanan kami kepada pesaing Anda dan kami tidak menjanjikan eksklusivitas di segmen pasar tertentu. Anda lebih lanjut mengakui dan menyetujui bahwa karyawan PT. Ide Picup Group mungkin juga adalah Pelanggan/pemilik Toko Online dan bahwa mereka dapat bersaing dengan Anda. Dalam hal ini mereka tetap tunduk pada Kebijakan Privasi PT. Ide Picup Group dalam perlindungan data pengguna PT. Ide Picup Group.</p>
                                                <p>Jika terjadi perselisihan mengenai kepemilikan Akun, kami berhak meminta dokumentasi untuk menentukan atau mengkonfirmasi kepemilikan Akun. Dokumentasi tersebut adalah dokumen apapun yang terkait dengan Akun tersebut, termasuk namun tidak terbatas kepada kartu identitas yang terdaftar di PT. Ide Picup Group atau dokumen lainnya yang tidak dilarang oleh ketentuan hukum yang berlaku.</p>
                                                <p>PT. Ide Picup Group memiliki hak untuk menentukan, dalam penilaian kami sendiri, kepemilikan Akun yang sah, dan mentransfer Akun kepada pemilik yang sah. Jika kami tidak dapat secara wajar menentukan pemilik Akun yang sah, tanpa mengesampingkan hak dan upaya hukum, PT. Ide Picup Group berhak untuk menonaktifkan sementara Akun hingga keputusan telah ditentukan antara pihak yang berselisih.</p>
                                                <p>PT. Ide Picup Group memiliki hak untuk menolak, membatalkan, menghentikan, menangguhkan, mengunci, atau memodifikasi akses ke (atau kontrol) Akun atau Layanan apapun (termasuk hak untuk membatalkan atau mentransfer pendaftaran nama domain) karena alasan apapun (sebagaimana ditentukan oleh PT. Ide Picup Group dalam kebijakannya sendiri dan mutlak), termasuk namun tidak terbatas pada hal-hal berikut: (i) untuk memperbaiki kesalahan yang dibuat oleh PT. Ide Picup Group dalam menawarkan atau memberikan Layanan apa pun (termasuk pendaftaran nama domain), (ii) untuk melindungi integritas dan stabilitas, dan memperbaiki kesalahan yang dilakukan oleh, registrasi nama domain atau registrar, (iii) untuk membantu upaya deteksi dan pencegahan penipuan dan penyalahgunaan kami, (iv) untuk mematuhi perintah pengadilan terhadap Anda dan/atau nama domain atau Aplikasi web Anda dan lokal yang berlaku, undang-undang dan peraturan, nasional dan internasional, (v) untuk mematuhi permintaan penegakan hukum, termasuk permintaan panggilan pengadilan, (vi) untuk mematuhi setiap proses penyelesaian sengketa, (vii) untuk membela setiap tindakan hukum atau ancaman tindakan hukum tanpa pertimbangan apakah tindakan hukum tersebut atau tindakan hukum yang terancam akhirnya ditentukan untuk bersama atau tidak berdasar, (viii) untuk menghindari tanggung jawab perdata atau pidana dari pihak PT. Ide Picup Group, para pejabat, direktur, karyawan dan agennya, serta Afiliasi PT. Ide Picup Group, termasuk, namun tidak terbatas pada, contoh di mana Anda telah menggugat atau mengancam akan menuntut PT. Ide Picup Group, atau (ix) untuk menanggapi jumlah keluhan yang berlebihan terkait dengan cara apapun ke Akun Anda, nama domain, atau konten di Aplikasi web Anda yang dapat mengakibatkan kerusakan pada bisnis, operasi, reputasi, atau pemegang saham PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>PEMBELIAN DAN PEMBAYARAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>9.1&nbsp;PT. Ide Picup Group mendukung satu atau lebih metode pembayaran berikut:</p>
                                                <p>(i) &nbsp; &nbsp; &nbsp; Kartu kredit</p>

                                                <p>Pembayaran dengan kartu diproses melalui saluran pembayaran pihak ketiga dan jenis kartu kredit yang diterima oleh saluran pembayaran ini mungkin bervariasi tergantung pada yurisdiksi tempat anda berada;</p>
                                                <p>(ii) &nbsp; &nbsp; Transfer Bank</p>
                                                <p>Pembeli dapat melakukan pembayaran melalui Anjungan Tunai Mandiri (ATM) atau transfer bank via internet (&ldquo;Transfer Bank&rdquo;) ke Rekening Garansi PT. Ide Picup Group kami yang ditunjuk (seperti yang ditentukan dalam Bagian 13). Pembeli harus memberikan bukti transfer atau referensi transaksi pembayaran kepada PT. Ide Picup Group untuk tujuan verifikasi melalui fungsi &apos;Unggah Bukti Pembayaran&apos; yang dapat ditemukan di aplikasi PT. Ide Picup Group sebagai konfirmasi pembayaran. Apabila konfirmasi pembayaran tidak diterima PT. Ide Picup Group dalam tiga (3) hari, pesanan Pembeli akan dibatalkan;</p>
                                                <p>(iii) &nbsp; &nbsp; 3rd Party Payment Gateway (PT. Midtrans dan PT. Duitku dan PT. Kredivo)</p>
                                                <p>Dalam menyediakan Layanan Pembayaran PT. Ide Picup Group, PT. Ide Picup Group memfasilitasi Anda dengan Penyedia Layanan Pihak Ketiga, dalam hal ini 3rd Party Payment Gateway (PT. Midtrans dan PT. Duitku dan PT. Kredivo). Untuk mendukung Layanan Pembayaran PT. Ide Picup Group, setiap Akun Toko Online akan dibuatkan e-wallet atas nama PT. Ide Picup Group (thefepi_ yang berfungsi sebagai penyimpanan sementara dana pembayaran transaksi.</p>
                                                <p>(iv) &nbsp; &nbsp; &nbsp;metode pembayaran lainnya sebagaimana ditampilkan di Aplikasi.</p>

                                                <p>9.2 &nbsp; &nbsp; Pembeli boleh mengganti mode pembayaran pilihan untuk pembelian mereka hanya sebelum melakukan pembayaran.</p>
                                                <p>9.3 &nbsp; &nbsp; PT. Ide Picup Group tidak bertanggung jawab dan tidak memiliki kewajiban apapun untuk setiap kerugian atau kerusakan terhadap Pembeli yang timbul dari informasi pengiriman dan/atau informasi pembayaran yang dimasukkan oleh Pembeli atau pengiriman uang yang salah oleh Pembeli sehubungan dengan pembayaran untuk barang yang dibeli. Kami berhak untuk mengecek apakah Pembeli berwenang untuk menggunakan metode pembayaran tertentu, dan dapat menangguhkan transaksi sampai otorisasi tersebut dikonfirmasi atau membatalkan transaksi yang bersangkutan bila konfirmasi tersebut tidak tersedia.</p>
                                            </p>
                                        </li>
                                        <li>PENGIRIMAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Dengan fitur Layanan Sistem Pengiriman PT. Ide Picup Group, Toko Online dapat menyediakan bagi Pelanggannya pilihan berbagai penyedia jasa pengiriman barang.</p>
                                                <p>Dalam menyediakan Layanan Jasa Pengiriman PT. Ide Picup Group, PT. Ide Picup Group memfasilitasi Anda dengan Penyedia Layanan Pihak Ketiga (sebagaimana didefinisikan di Pasal 7.1 Ketentuan Layanan PT. Ide Picup Group) yang merupakan perusahaan jasa pengiriman terpercaya, yaitu: JNE</p>
                                                <p>(masing-masing disebut &quot;Jasa Pengiriman&quot;)</p>
                                                <p>Untuk setiap kegiatan pengiriman oleh Jasa Pengiriman, Pelanggan akan dikenakan biaya jasa pengiriman (&quot;Biaya Pengiriman&quot;) yang secara otomatis menambah jumlah yang harus dibayar Pelanggan.</p>
                                            </p>
                                        </li>
                                        <li>PERMASALAHAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>11.1 &nbsp; Masing-masing Pembeli dan Penjual berjanji dan setuju bahwa pihaknya tidak akan membawa gugatan atau sebaliknya menyatakan klaim atau komplain (baik perdata atau pidana) terhadap PT. Ide Picup Group (kecuali dimana PT. Ide Picup Group adalah Penjual dari produk yang terkait dengan klaim) sehubungan dengan setiap transaksi yang dibuat di Aplikasi atau permasalahan yang terkait dengan transaksi tersebut.</p>
                                                <p>11.2 &nbsp; Agar menjadi jelas, layanan yang disediakan berdasarkan Bagian 26 ini hanya tersedia untuk Pembeli yang dilindungi oleh Garansi PT. Ide Picup Group. Pembeli yang menggunakan cara pembayaran lainnya untuk pembeliannya harus menghubungi Penjual secara langsung.</p>
                                            </p>
                                        </li>
                                        <li>KONTRIBUSI ANDA UNTUK LAYANAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>12.1 &nbsp; Dengan mengirimkan Konten untuk disertakan pada Layanan, anda menyatakan dan menjamin bahwa anda mempunyai semua hak dan/atau izin yang diperlukan untuk memberikan izin di bawah ini kepada PT. Ide Picup Group. Anda juga mengakui dan setuju bahwa anda bertanggung jawab penuh untuk apapun yang anda posting atau sediakan pada atau melalui Layanan, termasuk, dengan tidak terbatas pada, keakuratan, keandalan, sifat, izin untuk hak, kepatuhan terhadap hukum dan batasan hukum yang berkaitan dengan kontribusi Konten. Dengan ini anda memberikan kepada PT. Ide Picup Group dan penerusnya izin yang berlaku di seluruh dunia, bersifat non eksklusif, bebas royalti, dapat disublisensikan dan dipindahkan yang tidak dapat dicabut kembali untuk menggunakan, menyalin, mendistribusikan, mempublikasikan ulang, mengirim, mengubah, menyesuaikan, membuat karya turunan, menampilkan secara terbuka, dan melakukan secara terbuka kontribusi Konten tersebut pada, melalui atau sehubungan dengan Layanan dalam format media apapun dan melalui saluran media apapun, termasuk, dengan tidak terbatas pada, untuk mempromosikan dan mendistribusikan ulang bagian dari Layanan (dan karya turunannya) tanpa perlunya atribusi dan anda setuju untuk melepaskan hak moral (dan setiap hak serupa di bagian dunia mana pun) sehubungan dengan hal itu. Anda memahami bahwa kontribusi anda dapat dikirim melalui berbagai jaringan dan diubah untuk menyesuaikan dan beradaptasi dengan persyaratan teknis.</p>

                                                <p>12.2 &nbsp; Setiap Konten, bahan, informasi atau ide yang anda posting pada atau melalui Layanan, atau kirimkan kepada PT. Ide Picup Group dengan cara apapun (masing-masing disebut &quot;Pengiriman&quot;), tidak dianggap rahasia oleh PT. Ide Picup Group dan dapat disebarkan atau digunakan oleh PT. Ide Picup Group tanpa kompensasi atau kewajiban kepada anda untuk tujuan apapun, termasuk, tetapi tidak terbatas pada, mengembangkan, memproduksi dan memasarkan produk. Dengan melakukan Pengiriman kepada PT. Ide Picup Group, anda mengakui dan setuju bahwa PT. Ide Picup Group dan/atau pihak ketiga lainnya secara independen dapat mengembangkan perangkat lunak, aplikasi, antarmuka, produk dan modifikasi serta perubahan yang serupa atau mirip dalam hal fungsi, kode atau karakteristik lainnya dengan ide yang terdapat dalam Pengiriman anda. Karena itu, dengan ini anda memberikan kepada PT. Ide Picup Group dan penerusnya izin yang bersifat kekal, berlaku di seluruh dunia, tidak eksklusif, bebas royalti, dapat disublisensikan dan dipindahkan yang tidak dapat dicabut kembali untuk mengembangkan barang yang disebutkan di atas, dan untuk menggunakan, menyalin, mendistribusikan, mempublikasikan ulang, mengirim, mengubah, menyesuaikan, membuat karya turunan, menampilkan secara terbuka, dan melakukan secara terbuka setiap Pengiriman pada, melalui atau sehubungan dengan Layanan dalam format media apapun dan melalui saluran media apapun, termasuk, dengan tidak terbatas pada, untuk mempromosikan dan mendistribusikan ulang bagian dari Layanan (dan karya turunannya). Ketentuan ini tidak berlaku untuk informasi pribadi yang tunduk pada kebijakan privasi kami kecuali apabila anda membuat informasi pribadi tersebut tersedia untuk umum pada atau melalui Layanan.</p>
                                            </p>
                                        </li>
                                        <li>GANTI RUGI
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Anda setuju untuk mengganti rugi, membela dan membebaskan PT. Ide Picup Group, dan para pemegang saham, anak perusahaan, afiliasi, direktur, petugas, agen, pemilik merek bersama dan mitra lainnya, serta karyawannya (secara bersama-sama disebut &quot;Pihak yang Diberi Ganti Rugi&quot;) dari dan terhadap setiap dan semua klaim, tindakan hukum (termasuk namun tidak terbatas pada tindakan hukum perdata dan pidana), proses hukum, dan gugatan dan semua hal yang berkaitan dengan kewajiban, kerugian, penyelesaian, penalti, denda, biaya dan pengeluaran (termasuk, dengan tidak terbatas pada, setiap biaya penyelesaian permasalahan lainnya) yang dikeluarkan oleh Pihak yang Diberi Ganti Rugi yang timbul dari atau berkaitan dengan: (a) setiap transaksi yang dibuat di Aplikasi, atau setiap permasalahan terkait dengan transaksi tersebut (kecuali dimana PT. Ide Picup Group adalah Penjual di dalam transaksi yang terkait dengan permasalahan tersebut), (b) Garansi PT. Ide Picup Group, (c) hosting, pengoperasian, pengelolaan dan/atau administrasi Layanan oleh atau atas nama PT. Ide Picup Group, (d) pelanggaran atas ketentuan apapun dari Syarat Layanan ini atau setiap kebijakan atau pedoman yang dirujuk di sini oleh anda, (e) penggunaan atau penyalahgunaan Layanan oleh anda, (f) pelanggaran terhadap hukum atau hak pihak ketiga oleh anda atau (g) setiap Konten yang diunggah oleh anda.</p>
                                            </p>
                                        </li>
                                        <li>KETERPISAHAN
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Apabila ada ketentuan dari Syarat Layanan ini yang dianggap melanggar hukum, batal, atau karena alasan apapun tidak dapat diberlakukan berdasarkan hukum di yurisdiksi tersebut, maka ketentuan tersebut harus dianggap dapat dipisahkan dari syarat dan ketentuan ini dan tidak akan memengaruhi keabsahan dan keberlakuan setiap ketentuan yang tersisa di yurisdiksi tersebut maupun keabsahan dan keberlakuan ketentuan tersebut berdasarkan hukum di yurisdiksi lainnya.</p>
                                            </p>
                                        </li>
                                        <li>HUKUM YANG MENGATUR
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Syarat Layanan ini diatur dan ditafsirkan sesuai dengan hukum Republik Indonesia. Kecuali diwajibkan lain oleh hukum yang berlaku, setiap permasalahan, kontroversi, klaim atau perbedaan dalam bentuk apapun yang timbul dari atau sehubungan dengan Syarat Layanan ini terhadap atau yang berkaitan dengan PT. Ide Picup Group atau salah satu Pihak yang Diberi Ganti Rugi berdasarkan Syarat Layanan ini akan dirujuk dan diselesaikan di Pengadilan Negeri Jakarta Selatan.</p>
                                            </p>
                                        </li>
                                        <li>KETENTUAN UMUM
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>16.1 &nbsp; PT. Ide Picup Group mencadangkan semua hak yang tidak secara tegas diberikan di sini.</p>
                                                <p>16.2 &nbsp; PT. Ide Picup Group dapat mengubah Syarat Layanan ini setiap saat dengan memposting Syarat Layanan yang direvisi di Aplikasi ini. Penggunaan Aplikasi oleh anda setelah perubahan tersebut diposting dianggap sebagai penerimaan anda terhadap Syarat Layanan yang direvisi tersebut.</p>
                                                <p>16.3 &nbsp; Anda tidak boleh memberikan, mensublisensikan atau memindahkan hak apapun yang diberikan kepada anda di sini atau mensubkontrakkan salah satu kewajiban anda.</p>
                                                <p>16.4 &nbsp; Tidak ada hal dalam Syarat Layanan ini yang dapat diartikan sebagai hubungan kemitraan, usaha patungan atau pemberi kuasa-penerima kuasa antara anda dan PT. Ide Picup Group, dan juga tidak memberikan wewenang kepada anda untuk mengeluarkan biaya atau kewajiban apapun atas nama PT. Ide Picup Group.</p>
                                                <p>16.5 &nbsp; Kegagalan PT. Ide Picup Group setiap saat atau pada waktu-waktu tertentu untuk mewajibkan pelaksanaan ketentuan apapun dalam Syarat Layanan ini tidak akan memengaruhi haknya dengan cara apapun di waktu kemudian untuk memberlakukan ketentuan tersebut kecuali ketentuan tersebut dikesampingkan secara tertulis.</p>
                                                <p>16.6 &nbsp; Syarat Layanan ini semata-mata untuk manfaat anda dan kami saja dan bukan untuk manfaat orang atau entitas lain, kecuali untuk afiliasi dan anak perusahaan PT. Ide Picup Group (dan masing-masing penerus dan wakil dari PT. Ide Picup Group dan afiliasi dan anak perusahaannya).</p>
                                                <p>&nbsp;</p>
                                                <p>16.7 &nbsp; Ketentuan yang tercantum dalam Syarat Layanan ini dan setiap perjanjian dan kebijakan yang disertakan atau dirujuk dalam Syarat Layanan ini membentuk seluruh perjanjian dan pemahaman antara para pihak sehubungan dengan Layanan dan Aplikasi dan menggantikan setiap perjanjian atau pemahaman sebelumnya antara para pihak sehubungan dengan hal tersebut. Dengan ini para pihak juga mengecualikan semua ketentuan yang tersirat. Dalam memasuki perjanjian yang dibentuk oleh Syarat Layanan ini, para pihak tidak bersandar pada informasi, pernyataan, garansi, pemahaman, kesanggupan, janji atau jaminan siapapun selain yang secara tegas diatur dalam Syarat Layanan ini. Setiap pihak dengan tidak dapat dicabut kembali dan tanpa syarat melepaskan semua klaim, hak dan upaya hukum yang seharusnya dimiliki Bagian ini sehubungan dengan hal apapun dalam ketentuan sebelumnya. Syarat Layanan ini tidak dapat dibantah, dijelaskan atau dilengkapi dengan bukti perjanjian sebelumnya, perjanjian lisan pada saat yang bersamaan atau setiap ketentuan tambahan yang konsisten.</p>
                                                <p>16.8 &nbsp; Anda setuju untuk mematuhi semua hukum, undang-undang, peraturan dan kode yang berlaku, termasuk setiap perubahannya (apabila ada), yang berkaitan dengan anti penyuapan dan korupsi termasuk dengan tidak terbatas pada Undang-Undang No. 11 Tahun 1980 tentang Tindak Pidana Suap, Undang-Undang No. 31 Tahun 1999 tentang Pemberantasan Tindak Pidana Korupsi, sebagaimana diubah terakhir dengan Undang-Undang No. 20 Tahun 2001, Undang-Undang Penyuapan Inggris, dan Undang-Undang Praktik Korupsi Luar Negeri AS serta menegaskan bahwa anda memiliki dan akan memiliki semua kebijakan dan prosedur yang diperlukan untuk memastikan kepatuhan terhadap persyaratan tersebut.</p>
                                                <p>16.9 Jika ditemukan pembayaran dengan tujuan tidak menyenangkan, seperti peretasan, maka dana tidak dapat dikembalikan dan proses perdata atau pidana dapat dilakukan.</p>
                                                <p>16.10 &nbsp;Apabila anda memiliki pertanyaan atau masalah tentang Syarat Layanan ini atau persoalan yang muncul dalam Syarat Layanan ini atau di Aplikasi, silakan hubungi kami di: help@thefepi.com</p>
                                            </p>
                                        </li>
                                    </ol>

                                    <p><b>Pemberitahuan Terkait Dengan Hukum:</b> Silakan kirimkan semua pemberitahuan hukum ke help@thefepi.com&nbsp;dan tuliskan Untuk Perhatian &ldquo;Penasihat Umum&rdquo;.</p>

                                    <p>SAYA TELAH MEMBACA PERJANJIAN INI DAN MENYETUJUI SEMUA KETENTUAN YANG TERCANTUM DI ATAS DAN SETIAP REVISI YANG DILAKUKAN ATASNYA SESUDAHNYA. DENGAN MENGKLIK TOMBOL &quot;DAFTAR&quot; PADA SAAT PENDAFTARAN, SAYA MEMAHAMI BAHWA SAYA SEDANG MEMBUAT TANDA TANGAN DIGITAL, YANG SAYA MAKSUDKAN UNTUK MEMILIKI KEKUATAN DAN AKIBAT YANG SAMA SEPERTI BILA SAYA MENANDATANGANI NAMA SAYA SECARA MANUAL.</p>

                                    <p>Terakhir kali diubah: 12 Oktober 2021</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col text-left">
                                    <br></br>
                                    <h4 style={{ borderBottom: 'solid black 3px', fontSize: '20px' }}>Terms and Conditions<br></br><br></br></h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-left">
                                    <ol className="list-inline" style={{ fontWeight: 'bold' }}>
                                        <li>INTRODUCTION
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>1.1 &nbsp; &nbsp;Welcome to PT. Ide Picup Group (&ldquo;Application&rdquo;) platform. Please read the following terms of service carefully before proceeding with the application or opening an account with PT. Ide Picup Group (&ldquo;account&rdquo;) to know the legal rights and obligations in connection with PT. Ide Picup Group International Indonesia and its affiliates and subsidiaries (each and together, &lsquo;PT. Ide Picup Group&rdquo; or &ldquo;we&rdquo;). &ldquo;Service&rdquo; that we offer or provide including (a) Application, (b) service provided by application and software by PT. Ide Picup Group available on application, and (c) every information, landing page, features, datas, texts, images, pictures, graphics, music, sounds, videos (including livestreams), messages, tags, content, programming, software, application services (including, but not limited to, every mobile application service) or other materials available on the application or related services (&lsquo;content&rdquo;). Every new feature added or raising the service is subject to this terms of service. These terms of service also manage the service usage that is provided by PT. Ide Picup Group.</p>
                                                <p>1.2 &nbsp; &nbsp; Before becoming a user of the application, one has to read and accept all terms and conditions related to the terms of service and give agreement regarding the personal data procession as stated on the privacy policy regarding this terms of service.&nbsp;</p>
                                                <p>1.3 &nbsp; &nbsp;PT. Ide Picup Group has the right to change,suspend, or terminate everything or some parts from the application or services at any time or after giving a notice as required by the law and regional rules. PT. Ide Picup Group sebagaimana dipersyaratkan oleh undang-undang dan peraturan setempat. PT. Ide Picup Group dapat meluncurkan Layanan tertentu atau fitur tertentu dalam versi beta, yang mungkin tidak berfungsi dengan baik atau sama seperti versi akhir, dan kami tidak bertanggung jawab dalam hal demikian. PT. Ide Picup Group juga dapat membatasi fitur tertentu atau membatasi akses Anda ke bagian atau seluruh Aplikasi atau Layanan atas kebijakannya sendiri dan tanpa pemberitahuan atau kewajiban.</p>
                                                <p>1.4 &nbsp; &nbsp; PT. Ide Picup Group reserves the right to refuse to grant you access to the Application or Services or allow you to open an Account for any reason. By creating an Account (as defined below) at PT. Idea Pickup Group or use the Services of PT. Ide Picup Group (as defined below), then you as the Account Owner (as defined below) declare that you agree to be bound by the PT. Ide Picup Group and policies regarding changes or improvements to the Terms of Service in the future (&quot;PT. Ide Picup Group Terms of Service&quot;).</p>
                                                <p>Read the Terms of Service PT. The following Picup Group ideas carefully before using the PT. Ide Picup Group. You are welcome not to use the ideas of PT. Ide Picup Group if it does not align by the Terms of Service of PT. Ide Picup Group</p>
                                            </p>
                                        </li>
                                        <li>PRIVACY
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>2.1&nbsp;&nbsp; &nbsp;&nbsp;Your privacy is very important to us at PT. Ide Picup Group. To better protect your rights we have provided PT. Ide Picup Group&apos;s idea to explain our privacy practices in detail. By using the Service or providing information on the Application, you:</p>

                                                <p>(a) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Approved the action of PT. Ide Picup Group&apos;s idea to collect, use, disclose and/or process your Content, personal data and User Information as described in the Privacy Policy</p>

                                                <p>(b) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Agreed and acknowledged that the ownership rights to User Information are jointly owned by you and PT. Ide Picup Group; and</p>

                                                <p>(c) &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Will not, directly or indirectly, disclose your user Information to any third party, or otherwise allow any third party to access or use your User Information, without the prior written consent of PT. Ide Picup Group.</p>

                                                <p>2.2&nbsp;&nbsp; &nbsp;&nbsp;Users in possession of other Users&apos; personal data through the use of the Services (&quot;Receiving Parties&quot;) hereby agree that, they will (i) comply with all applicable personal data protection laws in relation to such data; (ii) allow Users whose personal data has been collected by the Receiving Party (the &quot;Disclosing Party&quot;) to delete the data that has been collected from the Receiving Party&apos;s database; and (iii) allow the Disclosing Party to review any information that has been collected about them by the Receiving Party, in each case (ii) and (iii) above, in accordance with and where required by applicable law.</p>

                                            </p>
                                        </li>
                                        <li>LIMITED PERMISSION
                                            <p style={{ fontWeight: 'normal' }}>

                                                <p>3.1&nbsp;&nbsp; &nbsp;&nbsp;PT. Ide Picup Group grants you limited, revocable permission to access the Service subject to the terms and conditions of these Terms of Service. All exclusive Content, trademarks, service marks, brand names, logos and other intellectual property (&quot;Intellectual Property&quot;) displayed on the Application are the property of PT. Ide Picup Group and where applicable, third party owners identified in the Application. No right or permission is granted directly or indirectly to any party accessing the Application to use or reproduce Intellectual Property, and no party accessing the Application can claim any right, ownership or interest in it. By using or accessing the Service you agree to comply with copyright, trademark, service mark and all other applicable laws that protect the Service, the Application and its Content. You agree not to copy, distribute, republish, transmit, publicly display, publicly perform, modify, adapt, rent, sell, or create derivative works of any part of the Service, Application or Content thereof. You also may not, without our prior written consent, mirror or frame any part or all of the content of this Application on any other server or as part of any other web Application. In addition, you agree that you will not use any robot, spider or other automated device or manual process to monitor or copy our Content, without our prior written consent (such consent is deemed given for standard search engine technology used by search web applications). Internet to direct Internet users to this web Application).</p>

                                                <p>3.2&nbsp;&nbsp; &nbsp;&nbsp;You agree that PT. Ide Picup Group may for any reason in its sole discretion and with or without notice or liability to you or any third party, add, replace, and/or modify, model methods, algorithms or other automated processes available when providing the Services, including but not limited to: not limited to services related to Advertising PT. Ide Picup Group or other services provided by PT. Ide Picup Group.&nbsp;</p>

                                                <p>3.3&nbsp;&nbsp; &nbsp;&nbsp;You may link to the Application from your web application, provided that your web application does not imply endorsement by or association with PT. Ide Picup Group. You acknowledge that at its sole discretion and at any time PT. Ide Picup Group may stop providing the Services, either in part or in whole, without notice.</p>

                                            </p>
                                        </li>
                                        <li>SOFTWARE
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>Any software provided by us to you as part of the Service is subject to the terms of these Terms of Service. PT. Ide Picup Group reserves all rights to the software which are not expressly granted by PT. Ide Picup Group in these Terms of Service. Any third party scripts or code linked to or referenced from the Service is licensed to you by the third party that owns such script or code, not PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>ACCOUNT AND SECURITY
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>5.1 You represent and warrant that you are a competent person according to law and have the authority to enter into an agreement and carry out its rights and obligations under the agreement.</p>
                                                <p>To be able to access and use the Services of PT. Ide Picup Group, you have to register at PT. Ide Picup Group by creating an Account.</p>
                                                <p>5.2 Account creation includes providing your full name, date of birth, domicile address, telephone number, valid email address. PT. Ide Picup Group may reject your application for Account creation, or cancel an existing Account, for any reason, at PT. Ide Picup Group.</p>
                                                <p>5.3 You are responsible for maintaining the security of your Account password. PT. Ide Picup Group is not and will not be liable for any loss or damage from your failure to keep your Account and password secure.</p>
                                                <p>5.4 PT. Ide Picup Group will not ask for a password or OTP code related to your Account for any reason, therefore PT. Ide Picup Group urges you not to give your account password or OTP code to any party, either to third parties or to parties on behalf of PT. Group Pickup Ideas.</p>
                                                <p>5.5 You acknowledge that you are responsible for the Content related to your Account and all activities you undertake in connection with the use of the Services and Content.</p>
                                                <p>5.6 You agree that PT. Idea Pickup Group will use the email address and phone number you provide as the primary method of communication.</p>
                                                <p>5.7 If PT. Ide Picup Group suspects any activity that violates the Terms of Service of PT. The idea of a Picup Group, then PT. Idea Picup Group reserves the right to label such Accounts &ldquo;Suspicious Accounts&apos;&apos;.</p>
                                                <p>5.8 In the event of any violation of the Terms of Service of PT. Idea Pickup Group, as determined at the discretion of PT. Idea Pickup Group, PT. The Ide Picup Group reserves the right to immediately terminate the Service for you.</p>
                                            </p>
                                        </li>
                                        <li>Seller Shop Conditions
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>6.1. Seller content type</p>
                                                <p>a) style: A photo of the seller&apos;s styling posted in the feed section (seller&apos;s creation)</p>
                                                <p>b) Products: Every product registered/marked by the seller (same as the product in our store)</p>

                                                <p>6.2. Content Terms/Restrictions:</p>
                                                <p>a) Each photo must tag at least 1 product (Photos posted without product tags are not allowed)</p>
                                                <p>b) Products that are NOT purchased by the seller or are NOT tagged in the styling photo CAN also be listed in the seller shop.</p>
                                                <p>c) If the number of products purchased &amp; registered: 10, the Seller can list the products that are NOT PURCHASE up to 10</p>
                                                <p>d) If the product is not purchased from us, but is sold in our STORE, the Seller can tag the product.</p>
                                                <p>e) If the product that the seller wants to tag/sell has not been entered in our STORE, then it CANNOT be tagged. (But if the seller wants to sell, you can request like Jastip)</p>

                                                <p>6.3. Minimum Posting to open a seller shop;</p>
                                                <p>a) Style: Min. 4 posts or more (no maximum limit)</p>
                                                <p>b) Product: Min. 4 products (min. 1 product per style)</p>
                                                <p>c) A maximum of 5 product tags in 1 style photo</p>

                                                <p>6.4. For products, Total number of tagging limits;</p>
                                                <p>a) Early opening: Up to 3 sellers can tag/sell products (First come, First Serve)</p>
                                                <p>b)But if the seller has bought the product, the seller can tag/sell unlimited</p>

                                                <p>6.5. Upload criteria for Style by seller</p>
                                                <p>a)Based on photos where items in clothing can be seen CLEARLY (Full or Partial)</p>
                                                <p>b) Low resolution, poor composition, or outfits that are not clearly visible can be rejected</p>
                                                <p>c) Photos not related to styling photos, inappropriate content and photos using your own shop/brand/product will be rejected</p>

                                                <p>6.6. Filtering process for photos</p>
                                                <p>a) Early opening: Will be posted after prior consultation with selected seller</p>
                                                <p>b)Basically, the &apos;pre-screening&apos; process for content uploaded by sellers is DELETED</p>
                                                <p>c) Screening and guidance is done randomly by random staff</p>

                                                <p>d)The report function will be introduced, so that users are able to report malicious images directly, and the staff will do a review upon the images beforehand.&nbsp;</p>
                                                <p>e)Correction/deletion of images (fist) &gt; if repeated (more than 3 times), a warning/account suspension will be taken.</p>

                                                <p>6.7. Seller Reward &nbsp;</p>
                                                <p>a) When a customer buys a product through a seller&rsquo;s post/link/submission, the reward will start to be generated!&nbsp;</p>
                                                <p>b)Reward income/commission: 5% of product price (set amount at initial opening)</p>

                                                <p>6.8. Seller Reward Monetization Requirements;</p>
                                                <p>a)When a customer purchases through a seller shop, 5% of the selling price is recognize as an &lsquo;Unconfirmed balance(revenue)</p>
                                                <p>b)After the &lsquo;purchase confirmation&rsquo; (&lsquo;Order complete&rsquo;) is being changed to &lsquo;Withdrawable Balance(revenue)&rsquo; then it can be cashed out</p>
                                                <p>c)&lsquo;Withdrawable balance&rsquo; can be withdrawn at least above Rp.50,000 (IDR)</p>

                                                <p>6.9. Reward Monetization Options;</p>
                                                <p>a)Available monetization options: i. Cash Payment ii. SHOP Poinonation</p>
                                                <p>b)&apos;Cash-out&apos; can be proceeded via Bank Transfer - It requires seller information (name, ID card, etc.) - Seller enters Bank information/Account Number/Account holder name - Cash withdrawal after VAT or 10% tax deduction - Transaction fee will be charged when transferring to a bank other than our company&rsquo;s registered bank - the process may take up to 3-5 days after submission.</p>
                                                <p>c)If it has been converted into Shop points, it cannot be returned to money or to cancel the conversion</p>

                                                <p>6.10. Seller&rsquo;s Donation Scheme;</p>
                                                <p>a)Seller can set the donation amount in % before starting the sale (if the % is set to 0.5%, the seller&rsquo;s profit will be 4.5% from 5%)</p>
                                                <p>b)The details of the Seller&rsquo;s arrangement are also notified to the customer in advance at the Seller Shop (for example, Rp.xxx will be donated to xxx)</p>
                                                <p>c)Donations can be selected even after the monetization process after the sale&nbsp;</p>

                                                <p>6.11.How Seller Donations Work;</p>
                                                <p>a)Partnership with related communities/Social Groups (example : Environmental Protection, Child Welfare, Animal Care, etc.)</p>
                                                <p>b)The company promotes/exposes the progress of donation activities every month on a regular basis</p>
                                            </p>
                                        </li>
                                        <li>TERMS OF USE
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>7.1 &nbsp; &nbsp; Permission to use this application and service is valid until terminated. This license will terminate as set out in these Terms of Service or if you fail to comply with any term or condition of these Terms of Service. In such cases, PT. Ide Picup Group may perform such termination with or without giving notice to you.</p>
                                                <p>7.2 &nbsp; &nbsp; You agree not to:</p>
                                                <p>(a) &nbsp; &nbsp; &nbsp; upload, post, transmit or create a content that trespass the law, harmful, threatening, abusive, harassing, worrying, disturbing, tortuous, libelous, vulgar, obscene, defamatory, invasive of another&rsquo;s privacy, hateful, or contains elements of SARA or others;</p>
                                                <p>(b) &nbsp; &nbsp; upload, post, transmit or otherwise make available content featuring unsupervised minors or use the services to harm minors in any way;</p>
                                                <p>(c) &nbsp; &nbsp; &nbsp; &nbsp;use the service or upload content to impersonate another person or entity, or otherwise misrepresent your affiliation with a person or entity;</p>
                                                <p>(d) &nbsp; &nbsp; &nbsp;falsify header or otherwise manipulate identifiers to disguise the origin of any content transmitted through the service;</p>
                                                <p>(e) &nbsp; &nbsp; &nbsp; &nbsp;remove notice of ownership rights from the Application;</p>
                                                <p>(f) &nbsp; &nbsp; &nbsp; &nbsp;cause, permit or authorize the modification, creation of derivative works, or translation of the service without the permission of PT. Ide Picup Group;</p>
                                                <p>(g) &nbsp; &nbsp; &nbsp;use the services for the benefit of third parties or in a manner not permitted by the permissions granted herein;</p>
                                                <p>(h) &nbsp; &nbsp; &nbsp; use the services or upload content in a way that deceives, scamming, falsifies, misleads or defrauds;</p>
                                                <p>(i) &nbsp; &nbsp; &nbsp; open and/or operate multiple user accounts in connection with actions that clearly violate these Terms of Service or the intent of these terms of Service;</p>
                                                <p>(j) &nbsp; &nbsp; accessing the application, opening a user account, or otherwise accessing a user account by using any unauthorized hardware and software from PT. Ide Picup Group, including but not limited to, emulators, simulators, bots, or similar hardware or software;</p>
                                                <p>(k) &nbsp; &nbsp; &nbsp; &nbsp; manipulate the price of any item or interfere with other users list;</p>
                                                <p>(l) &nbsp; &nbsp; &nbsp; &nbsp;take actions that could damage the feedback or rating system;</p>
                                                <p>(m) &nbsp; &nbsp;attempt to decompile reverse engineer, disassemble or hack the service (or any part of thereof), or to defeat or overcome any encryption technology or security measures implemented by PT. Ide Picup Group in connection with the service and/or data sent, processed or stored by PT. Ide Pickup Group;</p>
                                                <p>(n) &nbsp; &nbsp; &nbsp; &nbsp;take or collect any information about or regarding other account holders, including, without limitation, any personal data or information;</p>
                                                <p>(o) &nbsp; &nbsp; &nbsp; &nbsp;upload, emailed, post, transmit or otherwise make available any content that you are not authorized to provide under a legal or contractual or fiduciary bond (such as inside information, proprietary and confidential information learned or disclosed as part of an employment relationship or under a non-disclosure agreement);</p>
                                                <p>(p) &nbsp; &nbsp; &nbsp; &nbsp; upload, emailed, post, transmit or otherwise make available any content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</p>
                                                <p>(q) &nbsp; &nbsp; upload, emailed, post, transmit or otherwise make available any unwanted or unauthorized advertising, promotional material, &ldquo;junk mail&rdquo;, &ldquo;spam&rdquo;, &ldquo;chain letters&rdquo;, &ldquo;pyramid schemes&rdquo;, or any other unauthorized form of solicitation;</p>
                                                <p>(r) &nbsp; &nbsp; &nbsp; &nbsp;upload, emailed, post, transmit or otherwise make available any material that contains viruses, worms, trojan-horse software or other computer code, routines, files or programs designed to directly or indirectly affect, manipulate, interfere with, destroy or limit functionality or the integrity of any computer software or hardware or data or telecommunications equipment;</p>
                                                <p>(s) &nbsp; &nbsp; &nbsp; &nbsp; interrupt the normal flow of dialogue, cause the screen to &ldquo;scroll&rdquo; faster than other users of the service&rsquo;s typing speed, or perform actions that negatively affect other users&rsquo; ability to communicate in real time;</p>
                                                <p>(t) &nbsp; &nbsp; &nbsp; &nbsp;affect, manipulate or interfere with the service of servers or networks connected to the service or other users use and enjoyment of the service, or disobey the requirements, procedures, policies or regulations of networks connected to the application;</p>
                                                <p>(u) &nbsp; &nbsp; &nbsp;any action taken or engage in behavior that may directly or indirectly damage, disable, overburden or interfere with the service or the servers or networks connected to the service;</p>
                                                <p>(v) &nbsp; &nbsp; &nbsp;use the service to knowingly or unintentionally violate any applicable local, state, national or international laws, rules, codes, directives, guideline, or policies or regulations including, without limitation, laws and requirements (whether legally enforceable or not) relating to anti-money laundering or anti-terrorism.</p>
                                                <p>(w) &nbsp; &nbsp; &nbsp; &nbsp;use the service to violate the privacy of others or to &ldquo;stalk&rdquo; or harass others;</p>
                                                <p>(x) &nbsp; &nbsp; &nbsp;violate the rights of PT. Ide Picup Group, including any intellectual property rights and any passing off of such intellectual property rights;</p>
                                                <p>(y) &nbsp; &nbsp; &nbsp; use the service to collect or store personal data about other users in connection with the prohibited behavior and activities set out above; and/or</p>
                                                <p>(z) &nbsp; &nbsp; &nbsp; register goods that infringe copyright, trademark or other intellectual property rights of third parties or use the services in a manner that would violate the intellectual property rights of other parties; and/or</p>
                                                <p>(aa) &nbsp; &nbsp; &nbsp;request, correspond to, and/or engage with, or provide information regarding user activities when using the service to media and/or research companies, for any purpose including by not limited to market research, satisfaction surveys, or other forms of marketing without the written consent of PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>VIOLATIONS OF OUR TERMS OF SERVICE
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>We reserve the right to change or discontinue the Service for any reason, by giving notice to the Account Owner prior to termination or change.</p>
                                                <p>We reserve the right to refuse to provide services to anyone for any reason at any time.&nbsp;</p>
                                                <p>We may, but have no obligation to, remove content and suspend or terminate accounts if we determine in our sole discretion that the goods or services offered, or content uploaded or posted to the online store, violate PT. Ide Picup Group as well as the applicable laws and regulations.</p>
                                                <p>Account abuse in any form verbally or in writing (including threats of abuse or threats of requests for compensation) for every customer of PT. Ide Picup Group, employees, members, or officers of PT. Ide Picup Group will result in immediate termination of the account.&nbsp;</p>
                                                <p>PT. Ide Picup Group does not pre-screen content and in this case, PT. Ide Picup Group has absolute discretion to refuse or remove any content from the service, including the Online Store.</p>
                                                <p>We reserve the right to provide our services to your competitors and we do not promise exclusivity in any particular market segment. You further acknowledge and agree that the employees of PT. Ide Picup Group may also be the customer/owner of the Online store and that they can compete with you. In this case they are still subject to the Privacy Policy of PT. Ide Picup Group in protecting user data of PT. Ide Picup Group.</p>
                                                <p>In the event of a dispute regarding account ownership, we reserve the right to request documentation to determine or confirm account ownership. The documentation is any document related to the account including but not limited to the identity card registered with PT. Ide Picup Group or other documents that are not prohibited by applicable law.</p>
                                                <p>PT. Ide Picup Group reserves the right to determine, in our sole judgment, the legal ownership of the account, and to transfer the account to the rightful owner. If we are unable to reasonably determine the rightful owner of the account, without prejudice to legal rights and remedies, PT. Ide Picup Group reserves the right to temporarily deactivate the account until a decision has been made between the disputing parties.</p>
                                                <p>PT. Ide Picup Group reserves the right to refuse, cancel, terminate, suspend, lock, or modify access to (or control of) any account or service (including the right to cancel or transfer domain name registrations) for any reason (as determined by PT. Ide Picup Group in its sole and absolute discretion), including but not limited to the following: (i) to correct errors made by PT. Ide Picup Group in offering or providing any service (including domain name registration), (ii) to protect integrity, and stability, and correct errors made by, domain name registration or registrar, (iii) to assist fraud detection and prevention efforts and our abuse, (iv) to comply with court orders against you and/or your domain name or web application and applicable local, national and international laws and regulations, (v) to comply with law enforcement requests, including subpoena requests, (vi) to comply with any dispute resolution process, (vii) to defend any legal action without consideration of whether such legal action or legal action is threatened ultimately determined to be joint or unfounded, (viii) to avoid civil or criminal liability on the part of PT. Ide Picup Group, its officers, directors, employees and agents, and affiliates of PT. Ide Picup Group, including, but not limited to, instances where you have sued or threatened to sue PT. Ide Picup Group or (ix) to respond to an excessive number of complaints related in any way to your account, domain name, or content on your web application that may result in damage to the business, operations, reputation or shareholders of PT. Ide Picup Group.</p>
                                            </p>
                                        </li>
                                        <li>PURCHASE AND PAYMENT
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>9.1&nbsp;PT. Ide Picup Group supports one or more of the following payment methods:</p>
                                                <p>(i) &nbsp; &nbsp; &nbsp; Credit Card</p>
                                                <p>Payments made using cards will be processed through third-party payment channels, and the types of cards accepted through those payment channels may vary depending on the country in which you are located.</p>
                                                <p>(ii) &nbsp; &nbsp;Bank Transfer</p>
                                                <p>Customers may make payments to PT. Ide Picup Group&apos;s preferred warranty account via Automatic Teller Machine (ATM) or internet bank transfer (&quot;Bank Transfer &apos;&apos;) (as determined in Part 13). Customers must provide proof of transfer or transaction reference to PT. Ide Picup Group for verification reasons by utilizing the &apos;Upload Payment Receipt&apos; function available on PT. Ide Picup Group&apos;s application as a payment confirmation. If PT. Ide Picup Group rejects the customer&apos;s payment confirmation within three (3) days, the customer&apos;s order will be canceled;</p>
                                                <p>(iii) &nbsp; &nbsp; 3rd Party Payment Gateway (PT. Midtrans dan PT. Duitku dan PT. Kredivo)</p>
                                                <p>By allowing PT. Ide Picup Group&apos;s payment services, PT. Ide Picup Group connects You to a third-party payment gateway (PT. Midtrans dan PT. Duitku dan PT. Kredivo). To support PT. Ide Picup Group&apos;s payment services, each Online Shop Account will be created with an e-wallet called PT. Ide Picup Group (thefepi), which serves as a temporary storage location for transaction payment money.</p>
                                                <p>(iv) &nbsp; &nbsp;Other payment methods as displayed on Application</p>
                                                <p>9.2 &nbsp; &nbsp; &nbsp;Customers may modify their payment method for purchases only prior to completing a transaction.</p>
                                                <p>9.3 &nbsp; &nbsp; &nbsp;PT Ide Picup Group is not liable for and disclaims all liability for any loss or damage incurred by Customers as a result of incorrect shipment and/or payment information entered by Customers or incorrect cash transfer by Customer relating to item purchase. We are entitled to verify Customer&apos;s eligibility for a particular payment method and to proceed with the transaction until authorisation is confirmed, or to cancel it if confirmation is not available.</p>
                                            </p>
                                        </li>
                                        <li>SHIPMENT
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>The Online Store can offer its consumers a variety of delivery service providers using the Delivery System Service feature of PT. The Ide of Picup Group.</p>
                                                <p>In offering Shipping Services, PT. Ide Pickup Group connects you with a Third Party Service Provider (as specified in Article 7.1 of PT. Ide Picup Group&apos;s Terms of Service), which is a reputable delivery service provider, namely: JNE.</p>
                                                <p>(each denoted by the term &quot;Delivery Service&quot;)</p>
                                                <p>The Customer will be charged a delivery service fee (&quot;Shipping Fee&quot;) for each delivery activity performed by the Delivery Service, which will automatically increase the amount due by the Customer.</p>
                                            </p>
                                        </li>
                                        <li>ISSUE
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>11.1 Each Customer and Seller agrees and undertakes not to file a lawsuit or make any other claim or complaint (whether civil or criminal) against PT. Ide Picup Group (except where PT. Ide Picup Group is the Seller of the product subject to the claim) in connection with any transactions conducted through the Application or any issues arising from such transactions.</p>
                                                <p>11.2 To be clear, the services described in this Section 26 are offered solely to Customers covered by PT. Ide Picup Group&apos;s warrant. Customers who wish to make purchases using alternative payment options must contact the Seller directly.</p>
                                            </p>
                                        </li>
                                        <li>YOUR CONTRIBUTION TO SERVICES
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>12.1 By submitting Content for inclusion on the Service, you represent and warrant that you own or otherwise control all of the rights and/or permissions necessary to provide PT. Ide Picup Group those rights and/or permissions. Additionally, you acknowledge and agree that you are solely responsible for anything you post or make available on or through the Services, including, without limitation, the accuracy, reliability, nature, permissions for rights, compliance with applicable laws and legal restrictions, and the accuracy, reliability, nature, permissions for rights, compliance with applicable laws and legal restrictions relating to Content contributions. You hereby grant to PT. Ide Picup Group and its successors worldwide, non-exclusive, royalty-free, sublicensable, and irrevocable permission to use, copy, distribute, republish, transmit, modify, adapt, create derivative works, publicly display, and publicly contribute such Content to, through, or in connection with the Services in any media format and through any media channels, including, without limitation, to promote and redistribute portions of the Services (and You acknowledge that your contribution may be transmitted across several networks and may be modified to conform to and adapt to technical requirements.</p>

                                                <p>12.2 Any Content, materials, information, or ideas that you post on or through the Service, or that you transmit to PT. Ide Picup Group in any other way (each a &quot;Delivery&quot;) are not considered confidential by PT. Ide Picup Group&apos;s and may be distributed or used by PT. Ide Picup Group without compensation or liability to you for any purpose, including, but not limited to, developing, manufacturing, and marketing products. By submitting to PT. Ide Picup Group, you acknowledge and agree that PT. Ide Picup Group and/or other third parties may independently develop software, applications, interfaces, products, and modifications and changes that are functionally, aesthetically, or otherwise similar to the ideas contained in your Submission. As a result, you hereby grant to PT. Ide Picup Group and its successors a perpetual, worldwide, non-exclusive, royalty-free, sublicensable, and irrevocable license to develop the foregoing goods, as well as to use, copy, distribute, republish, transmit, modify, adapt, create derivative works, publicly display, and publicly perform any Submission on, through, or in connection with the Service in any media format and through any media channels, including, but not limited to (and derivative works). These Terms do not apply to personal information covered by our privacy statement unless you make it publicly available on or through the Service.</p>

                                            </p>
                                        </li>
                                        <li>COMPENSATION
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>You agree to indemnify, defend and hold harmless PT. Ide Picup Group, and its shareholders, subsidiaries, affiliates, directors, officers, agents, co-brand owners and other partners, and employees (collectively the &quot;Indemnified Parties&apos;&apos;) from and against any and all claims , legal actions (including but not limited to civil and criminal law actions), legal proceedings, and lawsuits and all matters relating to liabilities, losses, settlements, penalties, fines, costs and expenses (including, without limitation, any costs settlement of other problems) issued by the Indemnified Party arising out of or relating to: (a) any transactions made on the Application, or any issues related to such transactions (except where PT. Ide Picup Group is the Seller in the transaction related to the problem), (b) Guarantee PT. Ide Picup Group, (c) hosting, operating, managing and/or administering the Services by or on behalf of PT. Picup Group Idea, (d) violation of any provision of these Terms of Service or any policies or guidelines referenced herein by you, (e) your use or abuse of the Service, (f) violation of law or third party rights by you or (g) any Content uploaded by you.</p>
                                            </p>
                                        </li>
                                        <li>SEPARATION
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>If any provision of these Terms of Service is found to be unlawful, void, or unenforceable under the laws of any jurisdiction, that provision shall be deemed severable from these terms and conditions and shall have no effect on the validity and enforceability of any remaining provisions in that jurisdiction, as well as the validity and enforceability of such provisions under the laws of any other jurisdiction.</p>
                                            </p>
                                        </li>
                                        <li>GOVERNING LAW
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>These Terms of Service are regulated by and construed in compliance with Indonesian law. Unless required by relevant law, all disputes, conflicts, claims, or disagreements of any sort arising out of or in connection with these Terms of Service shall be resolved in favor of or against PT. Ide Picup Group or one of the Indemnified Parties may bring a claim against the other in accordance with these Terms of Service, which will be heard and resolved in the South Jakarta District Court.</p>
                                            </p>
                                        </li>
                                        <li>GENERAL PROVISION
                                            <p style={{ fontWeight: 'normal' }}>
                                                <p>16.1 All rights not expressly granted herein are reserved by PT. Ide Picup Group.</p>
                                                <p>16.2 PT. Ide Picup Group reserves the right to modify these Terms of Service at any time by posting the modified terms on this Application. Your continued use of the Application following the posting of such modifications constitutes acceptance of the new Terms of Service.</p>
                                                <p>16.3 You may not delegate, sublicense, transfer, or subcontract any of your rights or duties under this Agreement.</p>
                                                <p>16.4 Nothing in these Terms of Service shall be understood as creating a partnership, joint venture, or delegation of authority between you and PT. Ide Picup Group, or authorizing you to incur any expenditures or responsibilities on behalf of PT. Ide Picup Group.</p>
                                                <p>16.5 The failure of PT. Ide Picup Group to demand the implementation of any term of these Terms of Service at any time or in any specific instance shall not impact its right to enforce such provision in the future, unless such provision is waived in writing.</p>
                                                <p>16.6 Except for the affiliates and subsidiaries of PT. Ide Picup Group, these Terms of Service are only for your and our benefit (and their respective successors and representatives of PT. Ide Picup Group and its affiliates and subsidiaries).</p>

                                                <p>16.7 These Terms of Service, together with any agreements and policies incorporated or referred to in these Terms of Service, constitute the parties&apos; entire agreement and understanding with respect to the Service and Application, and supersede any prior agreement or understanding between the parties in this regard. Additionally, the parties expressly disclaim all implicit terms. The parties do not rely on any information, representation, warranty, understanding, undertaking, promise, or warranty other than those expressly provided for in these Terms of Service in entering into an agreement governed by these Terms of Service. Each party irrevocably and absolutely waives all claims, rights, and remedies that this Section may have in relation to any topic covered by the preceding sections. These Terms of Service cannot be contested, explained, or augmented by evidence of a prior agreement, concurrent oral agreement, or other consistent clauses.</p>
                                                <p>16.8 You agree to comply with all applicable laws, statutes, regulations, and codes, including any amendments thereto (if any), relating to anti-bribery and corruption, including without limitation Law No. 11 of 1980 concerning the Crime of Bribery, Law No. 31 of 1999 concerning the Eradication of Criminal Acts of Corruption, as last amended by Law No. 20 of 2001, the United Kingdom Bribery Act, and the United States Foreign Corrupt Practices Act, and confirms that you have and</p>
                                                <p>16.9 If a payment is discovered to have been made for an illegitimate purpose, such as hacking, the funds are forfeit and civil or criminal procedures may be instituted.</p>
                                                <p>16.10 If you have any questions or concerns about these Terms of Service, or any difficulties that may emerge from them or the Application, please contact us at help@thefepi.com.</p>
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <p><b>Legal Announcement:</b>&nbsp;Please submit any legal announcements to help@thefepi.com with the subject line &quot;For Attention: General Counsel.&quot;</p>
                            <p>&nbsp;</p>
                            <p>I HAVE READ THESE TERMS AND CONDITIONS AND AGREE TO ALL OF THE CONDITIONS INCLUDED AND TO ANY CHANGES MADE IN THE AFTERMATH. BY CLICKING THE &quot;REGISTER&quot; BUTTON DURING REGISTRATION, I UNDERSTAND THAT I WILL BE MAKING A DIGITAL SIGNATURE WITH THE SAME POWER AND EFFECTS AS WITH MANUAL SIGNATURE.</p>
                            <p>&nbsp;</p>
                            <p>Last changes: 12 October 2021</p>
                        </>
                }

            </div>
        );
    }
}

export default TermServiceScreen;